import React from 'react'
import { Box, Flex } from '@chakra-ui/react';
import { Order } from 'models/Order';
import DetailsText from 'components/details/DetailsText';

type TrackingCodeProps = {
  order: Order,
}

export default function TrackingCode({ order }: TrackingCodeProps) {
  return (
    <Flex justify="space-between" direction={{ base: 'column', md: 'row' }}>
      <Box>
        <DetailsText title="Código" value={order.tracking_code || '-'} />
        <DetailsText title="Url de Rastreamento" value={order.tracking_url  || '-'} />
      </Box>
    </Flex>
  );
}

import React from 'react';
import { Box } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import LoadingPage from 'components/loading/LoadingPage';
import ChargebackAlertDetails
	from 'views/admin/accountsBalance/components/ChargebackAlerts/components/ChargebackAlertDetails';
import ChargebackAlertsTable
	from 'views/admin/accountsBalance/components/ChargebackAlerts/components/ChargebackAlertsTable';
import { getChargebackAlerts } from 'services/PaymentsApi/chargebackAlerts';
import { ChargebackAlert } from 'models/ChargebackAlert';

export default function ChargebackAlertsMenu() {
	const [page, setPage] = React.useState(1);
	const { data: alerts, isLoading: isLoadingData } = useQuery(['chargebackAlerts', page], () => getChargebackAlerts(page));
	const [selectedAlert, setSelectedAlert] = React.useState<ChargebackAlert>(null);

	return (
		<Box>
			<LoadingPage isOpen={isLoadingData} />
			{selectedAlert ?
				<ChargebackAlertDetails selectedAlert={selectedAlert} setSelectedAlert={setSelectedAlert} /> :
				<ChargebackAlertsTable alertsResponse={alerts} page={page} setPage={setPage} setSelectedAlert={setSelectedAlert} />
			}
		</Box>
	);
}

export enum ProviderKey {
    PAGARME = 'pagarme',
    ZOOP = 'zoop',
    EVERMART = 'evermart',
    K8BANK = 'k8bank',
    CELCOIN = 'celcoin',
    IUGU = 'iugu',
    ABMEXPAY = 'abmexpay',
    ASAAS = 'asaas',
}

export type Provider = {
    id: number,
    name: string,
    key: ProviderKey,
}

export enum ProviderAccountStatus {
    ACTIVE = 'active',
    PENDING = 'pending',
    DISABLED = 'disabled',
}

export type ProviderAccount = {
    id: number,
    seller_id: number,
    account_id: string,
    provider: Provider,
    status: ProviderAccountStatus,
    in_use: boolean,
}

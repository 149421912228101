import { Box } from '@chakra-ui/react'
import DashboardTable from './components/DashboardTable'

export default function Dashboard () {
  
  return(
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
       <DashboardTable />
    </Box>
  )
}
import styled from "styled-components"

type SelectUserType = {
    cnpj_razao: string,
    antigo: Array<{
        cnpj_razao: string
    }>
    
}
export const LabelCheckbox = styled.div<{selectedUser: SelectUserType  }>`
    border-left-width: 1px;
  border-bottom-width: 1px;
  padding-left: 4px;
  padding-bottom: 2px;
  font-weight: bold;
  min-height: 20px;
  background-color: ${props => 
    props.selectedUser?.antigo && 
    props.selectedUser?.antigo[0] && 
    props.selectedUser?.antigo[0].cnpj_razao !== props.selectedUser?.cnpj_razao 
    ? 'yellow' 
    : ''};
`

export const RenderImgContainer = styled.div<{ fileName: File }>`
    border-radius: 5px;
    height: 80px;
    width: 600px;
    border: 0.5px solid ${props => props.fileName ? 'green' : 'grey'};
    background-color: ${props => props.fileName ? '#F0FFF0' : 'transparent'};
    display: flex;
    flex-direction: row;
    align-items: center;
`
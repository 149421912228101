import { adminApi } from "./api"



export const getAllUsers = async (params?: GetAllUsers.Params): Promise<GetAllUsers.Result> => {

    const adminUrl = `/api/admin/users`;
    try {
        // const users = await adminApi.get(adminUrl);

        const param: GetAllUsers.Params = {
            status: typeof params.status !== 'boolean' ? undefined : params.status,
            situation_id: params.situation_id == "" ? undefined : params.situation_id,
            invoicing: params.invoicing == "" ? undefined : params.invoicing,
            name: params.name == "" ? undefined : params.name,
            page: params.page,
            limit: params.limit,
            createdAt: params.createdAt == "" ? undefined : params.createdAt,
        }


        console.log({ paramsGerUser: params })

        const users = await adminApi.get(`${process.env.REACT_APP_API_URL}${adminUrl}`,
            {
                params: param
            });

        return users.data as GetAllUsers.Result;

    } catch (err) {
        console.log(err)
    }

}
export namespace GetAllUsers {
    export type Result = {
        data: User[];
        total: number;
    }

    export type Params = {
        status?: boolean;
        situation_id?: string;
        name?: string;
        invoicing?: string;
        page?: number;
        limit?: number;
        createdAt?: string;

    }
}


export type User = {
    id: number;
    name: string;
    email: string;
    remember_token?: string | null;
    created_at: string;
    updated_at: string;
    role_id: number;
    cpf: string;
    birth_date: string;
    zipcode: string;
    address: string;
    city: string;
    state: string;
    complement: string;
    number: number;
    document_attachment: string;
    address_document_attachment: string;
    activated: boolean;
    cellphone?: string;
    invoicing?: string;
    total_stores: number;
    situation_id: number;
    situation_name: 'Ativo' | 'Ativo e operante' | 'Ativo e inoperante' | 'Inativo';
    expired_situation_date: Date;
    complete_register: Boolean;
};

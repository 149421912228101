import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Collapse,
  SimpleGrid,
  Text,
  IconButton,
  useColorModeValue,
  
} from "@chakra-ui/react";
import {
  MdDelete,
  MdModeEdit,
  
} from "react-icons/md";
import { RowObj } from "../models/rowMembershipObj";
import { GetUsers } from "../models/userData";
import CheckTable from "./CheckTableUser";
import {
  GetAllMembershipPlans,
  MembershipPlan,
} from "../../../../services/AdminApi/membershipPlan";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useContextPlan } from "../../../../hooks/membershipContext";
import { transformDateToString } from "../../../../helper/formatDateToString";
import { useContextLocal } from "../variables/localContext";
import FilterUser from "./FilterUser";
import Pagination from "./Pagination";
import ModalDelete from "./ModalDeletePlan"
import ModalUpdate from "./ModalUpdatePlan"
export default function PaymentPlans() {
  // Chakra Color Mode
  const { getMembershipPlan, loading, membershipPlans } = useContextPlan();

  const {
    show,
    page,
    invoicing,
    limit,
    createdAt,
    name,
    situation,
    setParams,
    setOpenDeleteModal,
    setOpenUpdateModal,
    setIdPlan
  } = useContextLocal();

  // const handleSituation = (users: User) => {
  //   if (users.total_stores == 0) return "Inativo";

  //   return "Ativo e inoperante";
  // };
  useEffect(() => {
    (async () => {
      await getMembershipPlan();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const userTable: RowObj[] = membershipPlans.data.map(
        (value: MembershipPlan) => {
          return {
            id: value.id,
            name: value.name,
            status: value.status,
            value: value.total_price.toString(),
          };
        }
      );

      setData(userTable);
    })();
  }, [membershipPlans]);

  const updateMembershipPlan = (id: number) => {
    setIdPlan(id);
    setOpenUpdateModal(true);
  };

  const deleteMembershipPlan = (id: number) => {
    setIdPlan(id);
    setOpenDeleteModal(true);
  };
  
  const columnHelper = createColumnHelper<RowObj>();

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [data, setData] = React.useState<RowObj[]>([]);
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Nome do plano
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Status
        </Text>
      ),
      cell: (info) => {
        if (info.getValue()) {
          return (
            <Text color={textColor} fontSize="sm" fontWeight="700">
              Sim
            </Text>
          );
        } else {
          return (
            <Text color={textColor} fontSize="sm" fontWeight="700">
              Não
            </Text>
          );
        }
      },
    }),
    columnHelper.accessor("value", {
      id: "value",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Valor mensal
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("id", {
      id: "expander",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Ações
        </Text>
      ),
      cell: (info: any) => (
        <React.Fragment>
          <IconButton
            colorScheme="white"
            onClick={() => {
              updateMembershipPlan(info.getValue());
            }}
            aria-label="Search database"
            icon={<MdModeEdit color="black" />}
          />
          <IconButton
            colorScheme="white"
            aria-label="Search database"
            onClick={() => {
              deleteMembershipPlan(info.getValue());
            }}
            icon={<MdDelete color="black" />}
          />
        </React.Fragment>
        // <Flex align="center">
        //   <Text color={textColor} fontSize="sm" fontWeight="700">
        //     <Menu />
        //   </Text>
        // </Flex>
      ),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      // // columnFilters: []
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  if (!loading) {
    return <React.Fragment />;
  } else {
    return (
      <React.Fragment>
        <ModalDelete/>
        <ModalUpdate/>
        <SimpleGrid columns={{ base: 3, md: 3, xl: 3 }} gap="20px" mb="20px">
          <Button
            size="md"
            alignSelf="flex-start"
            onClick={() => {
              setParams({ show: !show });
            }}
          >
            Novo plano
          </Button>
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
          <Collapse in={show}>
            <FilterUser />
          </Collapse>

          <Box pt={{ base: "130px", md: "60px", xl: "40px" }}>
            <CheckTable tableData={data} table={table} tableName="Planos" />
            {/* <Pagination tableData={data} table={table} tableName="Lojas" /> */}
          </Box>
        </SimpleGrid>
      </React.Fragment>
    );
  }
}

import React from 'react';
import { Box } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getDubpayBalance } from 'services/PaymentsApi/accountsBalance';
import LoadingPage from 'components/loading/LoadingPage';
import { ProviderBalance } from 'models/ProviderBalance';
import ProviderBalanceContainer
	from 'views/admin/accountsBalance/components/ProvidersBalance/components/ProviderBalanceContainer';

export default function ProvidersBalance() {
  const { data: dubpayBalance, isLoading: isLoadingData } = useQuery(['dubpayBalance'], getDubpayBalance, {
		refetchOnWindowFocus: false,
	});

	return (
		<Box>
			<Box display="flex">
				{dubpayBalance?.map((balance: ProviderBalance) => (
					<ProviderBalanceContainer key={balance.provider} providerBalance={balance} />
				))}
			</Box>
			<LoadingPage isOpen={isLoadingData} />
		</Box>
	);
}

export function formatDate (dateString: string): string {
  if (dateString) {
    let date;
    if (dateString.includes('T')) {
      date = new Date(dateString);
    } else {
      date = new Date(dateString + "T00:00:00");
    }
    return date.toLocaleString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  }
  return "-";
}

export function formatTime (dateString: string): string {
  const date = new Date(dateString);
  return date.toLocaleString("pt-BR", {
    hour: "2-digit",
    minute: "2-digit",
  });
}

import { createContext, ReactNode, useState, useCallback, useContext } from 'react'
import { RowObj } from '../views/admin/visaoLojista/models/rowUserObj'
import { getAllUsers } from "../services"

import {getToken, GetToken} from "../services/AdminApi/auth"
import {GetAllUsers} from "../services/AdminApi/users"

interface ProviderProps {
    children: ReactNode;
}

interface ContextProps {
    getUserData: (params?: GetAllUsers.Params) => Promise<GetAllUsers.Result>;
    userData: GetAllUsers.Result;
    loading: boolean;
    tokenApi: string;
    getTokenApi: (params: GetToken.Params) => Promise<GetToken.Result>;
    totalResult: number;
}

export const ContextUserData = createContext<ContextProps | null>(null)

export function ContextProvider({ children }: ProviderProps) {

    const [userData, setUserData] = useState<GetAllUsers.Result>({data: [], total: 0})
    const [loading, setLoading] = useState(false);
    const [tokenApi, setTokenApi] = useState("");
    
    const getTokenApi = useCallback(
        async (params: GetToken.Params): Promise<GetToken.Result> => {
            try {
                const response: GetToken.Result = await getToken(params);

                setTokenApi(response.token)
                return response;

            } catch (err) {

            }
        }
    , [])

    const [totalResult, setTotalResult] = useState<number>(0)
    
    const getUserData = useCallback(
        async (params?: GetAllUsers.Params) => {

            try {
                setLoading(false);
                
                const response: GetAllUsers.Result = await getAllUsers(params);
                
                setTotalResult(response.total)
                setUserData(response)
               console.log({response})

                return response
            } catch (err: any) {
                setLoading(false);
                return {
                    data: [],
                    total: 0
                }
                console.log(err.response.data.error)

            } finally {
                setLoading(true);
            }
        }, []);

    const contextValue: ContextProps = {
        getUserData,
        userData,
        loading,
        tokenApi,
        getTokenApi,
        totalResult
        
    };

    return (
        <ContextUserData.Provider value= { contextValue }>
        { children }
        </ContextUserData.Provider>
      )
}

export function useContextUser() {
    const context = useContext(ContextUserData)

    return context
}






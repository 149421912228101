import styled from "styled-components";

// Defina um objeto de colunas por breakpoint com valores opcionais
const columnsByBreakpoints = {
  base: 1,
  sm: 2,
  md: 2,
  lg: 2,
  xl: 4,
  '2xl': 4,
};

type ColumnsByBreakpoints = Partial<typeof columnsByBreakpoints>;

export const CustomGridItem = styled.div<{
  columns?: ColumnsByBreakpoints; // Tornar os valores opcionais
}>`
  grid-column: span ${(props) => props.columns?.base || 1};

  // Defina o número de colunas para cada breakpoint
  @media (min-width: 576px) {
    grid-column: span ${(props) => props.columns?.sm || props.columns?.base || 1};
  }

  @media (min-width: 768px) {
    grid-column: span ${(props) =>
      props.columns?.md || props.columns?.sm || props.columns?.base || 1};
  }

  @media (min-width: 992px) {
    grid-column: span ${(props) =>
      props.columns?.lg ||
      props.columns?.md ||
      props.columns?.sm ||
      props.columns?.base || 1};
  }

  @media (min-width: 1200px) {
    grid-column: span ${(props) =>
      props.columns?.xl ||
      props.columns?.lg ||
      props.columns?.md ||
      props.columns?.sm ||
      props.columns?.base || 1};
  }

  @media (min-width: 1536px) {
    grid-column: span ${(props) =>
      props.columns?.['2xl'] ||
      props.columns?.xl ||
      props.columns?.lg ||
      props.columns?.md ||
      props.columns?.sm ||
      props.columns?.base || 1};
  }

  background-color: white;
  border-radius: 5px;
`;

// Exemplo de uso:
// <CustomGridItem columns={{ base: 2, lg: 3, '2xl': 4 }} />

export const HeaderContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

`

export const HeaderItem = styled.div`
    

`


import { paymentsApi } from "./api";
import { ProviderAccount } from 'models/ProviderAccount';
import { SellerPaymentType } from 'models/SellerPaymentType';

export const getUserProviderAccounts = async (userId: string): Promise<ProviderAccount[]> => {
  try {
    const url = `/provider_accounts/user/${userId}`;

    const accounts = await paymentsApi.get(url);

    return accounts.data.data;
  } catch (err) {
    console.log(err);
  }
};

export const getUserPaymentTypes = async (userId: string): Promise<SellerPaymentType[]> => {
  try {
    const url = `/payment_types/user/${userId}`;

    const paymentTypes = await paymentsApi.get(url);

    return paymentTypes.data.data;
  } catch (err) {
    console.log(err);
  }
};

export const migrateProviderAccount = async (userId: string, providerKey: string): Promise<void> => {
  const url = `/provider_accounts/migrate_account`;

  await paymentsApi.post(
    url,
    {
      user_id: userId,
      provider_key: providerKey
    }
  );
};

export const migratePaymentMethod = async (userId: string, providerKey: string, paymentMethodKey: string): Promise<void> => {
  const url = `/provider_accounts/migrate_payment_method`;

  await paymentsApi.post(
    url,
    {
      user_id: userId,
      provider_key: providerKey,
      payment_method_key: paymentMethodKey
    }
  );
};

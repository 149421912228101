import React from 'react';
import { Box } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getSellersBalance } from 'services/PaymentsApi/accountsBalance';
import LoadingPage from 'components/loading/LoadingPage';
import { SellersBalance } from 'models/SellerBalance';
import SellersBalanceTable from 'views/admin/accountsBalance/components/SellersBalance/components/SellersBalanceTable';
import SellerBalanceDetails
	from 'views/admin/accountsBalance/components/SellersBalance/components/SellerBalanceDetails';

export default function SellersBalanceMenu() {
	const [page, setPage] = React.useState(1);
	const [filters, setFilters] = React.useState({});
	const { data: sellers, isLoading: isLoadingData, refetch: refetchSellers } = useQuery(['sellersBalance', page, filters], () => getSellersBalance(page, filters), {
		refetchOnWindowFocus: false,
	});
	const [selectedSeller, setSelectedSeller] = React.useState<SellersBalance>(null);

	return (
		<Box>
			<LoadingPage isOpen={isLoadingData} />
				{selectedSeller ?
					<SellerBalanceDetails selectedSeller={selectedSeller} setSelectedSeller={setSelectedSeller} refetchSellers={refetchSellers} /> :
					<SellersBalanceTable sellersResponse={sellers} page={page} setPage={setPage} setFilters={setFilters} setSelectedSeller={setSelectedSeller} />
				}
		</Box>
	);
}

import { createStandaloneToast } from '@chakra-ui/react';

const toastConfig: object = {
  duration: 3000,
  isClosable: true,
  position: "top-right"
}

export const notifyError = (message: string) => {
  const toast = createStandaloneToast()
  toast({
    ...toastConfig,
    title: message,
    status: "error",
  })
}

export const notifyAlert = (message: string) => {
  const toast = createStandaloneToast()
  toast({
    ...toastConfig,
    title: message,
    status: "warning",
  })
}

export const notifySuccess = (message: string) => {
  const toast = createStandaloneToast()
  toast({
    ...toastConfig,
    title: message,
    status: "success",
  })
}

export const notifyInfo = (message: string) => {
  const toast = createStandaloneToast()
  toast({
    ...toastConfig,
    title: message,
    status: "info",
  })
}

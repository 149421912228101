import { AxiosError, AxiosResponse } from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { adminApi as api } from "services/AdminApi/api";
import { getToken } from "services/AdminApi/auth";

// Definindo o contexto de autenticação
const AuthContext = createContext<{
  user: any; // Aqui você pode substituir 'any' pelo tipo de usuário apropriado
  login: ({ email, password }: { email: string; password: string }) => void;
  logout: () => void;
  check: () => void;
}>({
  user: null,
  login: () => {},
  logout: () => {},
  check: () => {},
});

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const token = () => window.localStorage.getItem("token");
  const [user, setUser] = useState<any>(null); // Novamente, substitua 'any' pelo tipo de usuário

  const login = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    const response = await getToken({ email, password });
    const role = response?.data?.role?.name;

    if (role !== "admin") {
      throw new Error("Usuário sem permissão");
    }

    setUser(response.data);
    window.localStorage.setItem("token", response.token);
  };


  const redirectToAuth = () => {
    window.open("/#/auth", "_self");
  }

  const logout = () => {
    // Lógica para fazer o logout
    window.localStorage.removeItem("token");
    redirectToAuth()
  };

  const check = () => {
    // Lógica para verificar a autenticação
    setupAuthInterceptor();
    api.interceptors.response.use(handleAxiosSuccess, handleAxiosError);
  };

  // Função para configurar o token nos cabeçalhos das solicitações
  const setupAuthInterceptor = async () => {
    api.interceptors.request.use((config) => {
      if (token()) {
        config.headers["Authorization"] = `Bearer ${token()}`;
      }
      return config;
    });
  };

  // Função para manipular respostas bem-sucedidas
  const handleAxiosSuccess = (response: AxiosResponse) => {
    if (!token()) {
      redirectToAuth()
    }

    return response;
  };

  // Função para manipular erros
  const handleAxiosError = (error: AxiosError) => {
    if (!token() || error.response.status === 401) {
      redirectToAuth()
    }
    return Promise.reject(error);
  };

  useEffect(() => check(), [children]);
  useEffect(() => check(), []);

  return (
    <AuthContext.Provider value={{ user, login, logout, check }}>
      {children}
    </AuthContext.Provider>
  );
};

// Um hook para usar o contexto de autenticação
const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };

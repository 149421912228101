import React from 'react';
import {
  Input,
} from '@chakra-ui/react';
import { removeMoneyMask } from 'helper/formatters';
import { NumericFormat } from 'react-number-format';

type MoneyInputProps = {
  id: string;
  value: string;
  setValue: Function;
};

export default function MoneyInput({ id, value, setValue }: MoneyInputProps) {
  return (
    <NumericFormat
      customInput={Input}
      id={id}
      value={value}
      onChange={(event) => {
        const newValue = event.target.value;
        setValue(id, removeMoneyMask(newValue));
      }}
      required={true}
      prefix="R$ "
      allowNegative={false}
      decimalScale={2}
      decimalSeparator=","
      thousandsGroupStyle="thousand"
      thousandSeparator="."
      fixedDecimalScale
    />
  );
}

// Chakra imports
import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import Footer from 'components/footer/FooterAuth';
import FixedPlugin from 'components/fixedPlugin/FixedPlugin';
// Custom components
import { NavLink } from 'react-router-dom';
// Assets
import { FaChevronLeft } from 'react-icons/fa';
import desktopImage from './logo_home.png';

const ImageLogo = () => {
	return (
		<img src={desktopImage} />
	);
}

function AuthIllustration(props: { children: JSX.Element | string; illustrationBackground: string }) {
	const { children, illustrationBackground } = props;
	// Chakra color mode
	return (
		<Flex position='relative' h='max-content'>
			<Flex
				h={{
					sm: 'initial',
					md: 'unset',
					lg: '150vh',
					xl: '97vh'
				}}
				w='100%'
				maxW={{ md: '66%', lg: '1313px' }}
				mx='auto'
				pt={{ sm: '50px', md: '0px' }}
				px={{ lg: '30px', xl: '0px' }}
				ps={{ xl: '70px' }}
				justifyContent='start'
				direction='column'>
				<NavLink
					to='/admin'
					style={() => ({
						width: 'fit-content',
						marginTop: '40px'
					})}>
					{/* <Flex align='center' ps={{ base: '25px', lg: '0px' }} pt={{ lg: '0px', xl: '0px' }} w='fit-content'>
						<Icon as={FaChevronLeft} me='12px' h='13px' w='8px' color='secondaryGray.600' />
						<Text ms='0px' fontSize='sm' color='secondaryGray.600'>
							Back to Simmmple
						</Text>
					</Flex> */}
				</NavLink>
				{children}
				<Box
					display={{ base: 'none', md: 'block' }}
					h='100%'
					minH='100vh'
					w={{ lg: '50vw', '2xl': '44vw' }}
					position='absolute'
					paddingLeft={20}
					bg="#25312F"
					right='0px'>
					<Flex
						bg="#25312F"
						bgImage={desktopImage}
						bgRepeat={'no-repeat'}
						justify='center'
						align='end'
						w='50%'
						h='100%'
						bgSize='contain'
						bgPosition='50%'
						position='absolute'
						borderBottomLeftRadius={{ lg: '120px', xl: '200px' }}
					/>
				</Box>
				<Footer />
			</Flex>
		</Flex>
	);
}
// PROPS

AuthIllustration.propTypes = {
	illustrationBackground: PropTypes.string,
	image: PropTypes.any
};

export default AuthIllustration;

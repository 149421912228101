import React, {useEffect, useState} from "react"
import {Container, Row, Item, Header} from "./styles"
import Chart, {Props} from 'react-apexcharts';
import { Text } from "styleguide/Typograph"
import { Flex, Spinner } from "@chakra-ui/react";

interface Order {
  order_date: string;
  completed_total: number;
  pending_total: number;
  cancelled_total: number;
}

const ListOrders = (props: { orders: Order[] }) => {
    useEffect(() => {
      if (props.orders.length > 0) {
        setState({
          options: {
            stroke: {
              curve: "smooth",
              width: 4,
            },
            chart: {
              toolbar: {
                show: false
              },
              zoom: {
                enabled: false
              },
              id: 'apexchart-example',
              width: '100%'
            },
            grid: {
              borderColor: "#f1f1f1"
            },
            colors: ["#82E0AA", "#F4D03F", "#EC7063"],
            xaxis: {
              categories: props.orders.map((value: { order_date: string }, index: number) => {
                const date = new Date(value.order_date);
                return `${(date.getUTCDate()).toLocaleString().padStart(2, "0")}/${(date.getMonth()+1).toLocaleString().padStart(2, "0")}`;
            }),
            
            },
            markers: {
              size: 3,
            },
            dataLabels: {
              enabled: false
            },
            yaxis: {
              labels: {
                show: true
              }
            },
            legend: {
              show: true,
              position: 'top'
            },
          },
          series: [
            {
                name: 'Pago',
                color: "#6BAF76",
                data: props.orders.map((value: Order) => value.completed_total)
            },
            {
                name: 'Pendente',
                color: "#ffd700",
                data: props.orders.map((value: Order) => value.pending_total)
            },
            {
                name: 'Cancelado',
                color: "#FF6B6B",
                data: props.orders.map((value: Order) => value.cancelled_total)
            }
        ]
        })
      }
    }, [props.orders]);

    const [ state, setState] = useState<Props>({
        options: {},
        series: []
    });

    const [loading, setLoading] = useState(true);

    useEffect(() => {
      if (props.orders.length > 0) {
        setLoading(false); 
      }
    }, [props.orders]);

    return (
      <Container>
        <Header>
            <Item>
                <Text type="h4">
                    Resumo dos pedidos
                </Text>
            </Item>
        </Header>
        { loading ?
          <Flex direction={"column"}>
            <Spinner />
          </Flex>
        :
          <Row>
              <Chart
                options={state.options}
                series={state.series}
                type='line'
                width='100%'
                height='300px'
              />
          </Row>
        }
      </Container>
    )
}

export default ListOrders;

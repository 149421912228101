import { adminApi } from "./api";

export const getPaymentAdvanceRequest =
  async (): Promise<GetPaymentAdvanceRequest.Result> => {
    const adminUrl = `/api/seller/requests/pending`;
    try {
      const membershipPlans = await adminApi.get(
        `${process.env.REACT_APP_API_URL}${adminUrl}`
      );

      return membershipPlans.data as GetPaymentAdvanceRequest.Result;
    } catch (err) {
      console.log(err);
    }
  };

  export const getProcessedPaymentAdvanceRequest =
  async (): Promise<GetPaymentAdvanceRequest.Result> => {
    const adminUrl = `/api/seller/requests/processed`;
    try {
      const membershipPlans = await adminApi.get(
        `${process.env.REACT_APP_API_URL}${adminUrl}`
      );

      return membershipPlans.data as GetPaymentAdvanceRequest.Result;
    } catch (err) {
      console.log(err);
    }
  };

export const approvePaymentAdvanceRequest =
  async (params: ApprovePaymentAdvanceRequest.Params): Promise<ApprovePaymentAdvanceRequest.Result> => {
    const adminUrl = `/api/seller/payment-request/${params.idAdvance}/approve`;
    try {
      const membershipPlans = await adminApi.put(
        `${process.env.REACT_APP_API_URL}${adminUrl}`
      );

      return membershipPlans.data as ApprovePaymentAdvanceRequest.Result;
    } catch (err) {
      console.log(err);
    }
  };

export const cancelPaymentAdvanceRequest =
  async (params: CancelPaymentAdvanceRequest.Params): Promise<CancelPaymentAdvanceRequest.Result> => {
    const adminUrl = `/api/seller/payment-request/${params.idAdvance}/cancel`;
    try {
      const membershipPlans = await adminApi.put(
        `${process.env.REACT_APP_API_URL}${adminUrl}`
      );

      return membershipPlans.data as CancelPaymentAdvanceRequest.Result;
    } catch (err) {
      console.log(err);
    }
  };

export namespace ApprovePaymentAdvanceRequest {
  export type Params = {
    idAdvance: number;
  };

  export type Result = {
    data: number;
    success: boolean;
  };
}
export namespace CancelPaymentAdvanceRequest {
  export type Params = {
    idAdvance: number;
  };

  export type Result = {
    data: number;
    success: boolean;
  };
}

export namespace GetPaymentAdvanceRequest {
  export type Result = {
    data: PaymentAdvanceRequest[];
    success: boolean;
  };
}

export type PaymentAdvanceRequest = {
  id: number;
  user_id: number;
  name: string;
  amount: string;
  status: "pending" | "approved" | "cancelled";
  type: "withdraw" | "anticipation";
  admin_id?: number;
  created_at: Date;
  updated_at: Date;
};

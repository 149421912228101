import React from 'react';
import { Box, Button, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getSellerTransactions } from 'services/PaymentsApi/accountsBalance';
import { formatCurrency } from 'helper/currency';
import PaginationButtons from 'components/paginationButtons/PaginationButtons';
import { formatDate, formatTime } from 'helper/time';
import LoadingPage from 'components/loading/LoadingPage';
import { SellersBalance } from 'models/SellerBalance';
import { transactionsReport } from 'services/PaymentsApi/reports';
import { exportToExcelMultiPage } from 'helper/sheet';
import { notifyError } from 'utils/notification';

type SellerTransactionsProps = {
	seller: SellersBalance;
}

export default function SellerTransactions({ seller }: SellerTransactionsProps) {
	const [page, setPage] = React.useState(1);
	const [loading, setLoading] = React.useState(false);

	const { data: transactions, isLoading: isLoadingData } = useQuery(['sellerTransactions', seller, page], () => getSellerTransactions(seller.id, page), {
		refetchOnWindowFocus: false,
	});

	const generateTransactiosnReport = async () => {
		try {
			setLoading(true);
			const data = await transactionsReport(seller.id);
			const date = new Date().toISOString().split('T')[0];
			exportToExcelMultiPage([
				{
					name: 'Transações',
					data: data.transactions.map(transaction => ({
						...transaction,
						type: transaction.type === 'in' ? 'Entrada' : 'Saída',
						value: formatCurrency(transaction.value, 2),
					})),
				},
				{
					name: 'Lançamentos Futuros',
					data: data.waiting_funds.map(waitingFund => ({
						...waitingFund,
						value: formatCurrency(waitingFund.value, 2),
					})),
				}
			], `${date}-Transactions-${seller.first_name}-${seller.last_name}`)
		} catch (e) {
			notifyError('Erro ao gerar relatório de transações');
			console.log(e);
		} finally {
			setLoading(false);
		}
	}

	if (!isLoadingData) {
		return (
			<Box backgroundColor="white" px="10px" py="20px" borderRadius="20px" mt="20px">
				<LoadingPage isOpen={loading} />
				{transactions && transactions.data.length > 0 ? (
					<>
						<Box display="flex" justifyContent="space-between" alignContent="center" mb="20px" px="10px">
							<Text
								color="secondaryGray.900"
								fontSize="20px"
								fontWeight="700"
								lineHeight="100%"
							>
								Transações
							</Text>
							<Button onClick={generateTransactiosnReport} colorScheme='green' marginRight={4}>Relatório de Transações</Button>
						</Box>
						<TableContainer>
							<Table variant='simple'>
								<Thead>
									<Tr>
										<Th>Data</Th>
										<Th>Descrição</Th>
										<Th>Valor</Th>
										<Th>Tipo</Th>
									</Tr>
								</Thead>
								<Tbody>
									{transactions.data.map(transaction => (
										<Tr key={transaction.id} backgroundColor={
											transaction.type === "in" ? "rgba(0, 255, 0, 0.2)" : "rgba(255, 0, 0, 0.2)"
										}>
											<Td>{formatDate(transaction.date)} - {formatTime(transaction.date)}</Td>
											<Td>{transaction.description}</Td>
											<Td fontWeight="bold">{formatCurrency(transaction.value, 2)}</Td>
											<Td>{transaction.type === "in" ? "Entrada" : "Saída"}</Td>
										</Tr>
									))}
								</Tbody>
							</Table>
							<Box display="flex" alignItems="center" mt="20px">
								<PaginationButtons onClick={setPage} currentPage={page} lastPage={transactions?.meta?.last_page} />
							</Box>
						</TableContainer>
					</>
				) : (
					<>
						<Text
							color="secondaryGray.900"
							fontSize="20px"
							fontWeight="700"
							lineHeight="100%"
						>
							Nenhuma transação encontrada
						</Text>
					</>
					)}
			</Box>
		);
	} else {
		return (
			<LoadingPage isOpen={isLoadingData} />
		)
	}
}

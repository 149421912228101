import React from 'react';
import { ProviderAccount, ProviderKey } from 'models/ProviderAccount';
import { Flex, Text } from '@chakra-ui/react';
import StatusTag from 'views/admin/acessos/components/StatusTag';
import { SellerPaymentType } from 'models/SellerPaymentType';
import { PaymentMethodKey } from 'models/PaymentMethod';
import { getPaymentMethod } from 'utils/mappers';
import { notifyError, notifySuccess } from 'utils/notification';
import { migratePaymentMethod } from 'services/PaymentsApi/providerAccounts';
import LoadingPage from 'components/loading/LoadingPage';

type PaymentMethodDetailsProps = {
  userId: string,
  paymentMethod: PaymentMethodKey,
  providerAccount: ProviderAccount,
  sellerPaymentTypes: SellerPaymentType[] | null
  refetchSellerPaymentTypes: Function
}

export default function PaymentMethodDetails({ userId, paymentMethod, providerAccount, sellerPaymentTypes, refetchSellerPaymentTypes }: PaymentMethodDetailsProps) {
  const [loading, setLoading] = React.useState(false);

  const migrate = async () => {
    try {
      setLoading(true);
      await migratePaymentMethod(userId, providerAccount.provider.key, paymentMethod);
      notifySuccess(`${getPaymentMethod(paymentMethod)} migrado para a ${providerAccount.provider.name} com sucesso!`);
      refetchSellerPaymentTypes();
    } catch (e) {
      notifyError(`Erro ao migrar ${getPaymentMethod(paymentMethod)} para ${providerAccount.provider.name}.`);
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  const checkPaymentMethod = () => {
    if (!sellerPaymentTypes) return false;

    const sellerPaymentMethod = sellerPaymentTypes.find((sellerPaymentType: SellerPaymentType) =>
      sellerPaymentType.payment_method === paymentMethod
    );
    return sellerPaymentMethod && sellerPaymentMethod.provider_account === providerAccount.provider.key;
  }

  const active = checkPaymentMethod();

  if (paymentMethod !== PaymentMethodKey.PIX && providerAccount.provider.key === ProviderKey.K8BANK) return null;

  if (paymentMethod === PaymentMethodKey.CREDIT && providerAccount.provider.key === ProviderKey.IUGU) return null;

  return (
    <Flex align="center" onClick={active ? undefined : migrate} cursor={active ? undefined : "pointer"}>
      <LoadingPage isOpen={loading} />
      <StatusTag status={active ? 'active' : 'disabled'} size="16px" />
      <Text fontSize="16px" ml="6px">{getPaymentMethod(paymentMethod)}</Text>
    </Flex>
  );
}

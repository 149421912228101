import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Switch,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  SimpleGrid,
  Tab,
  TabList,
  Text,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
} from "@chakra-ui/react";
import { useContextUser } from "../../../../hooks/contextApi";
import { useContextPlan } from "../../../../hooks/membershipContext";
import { useContextLocal } from "../variables/localContext";
import { RowObj } from "../models/rowMembershipObj";
import Card from "components/card/Card";

export default function Users() {
  // Chakra Color Mode
  const { show, setParams } = useContextLocal();
  const { getUserData } = useContextUser();
  const { createMembershipPlan, getMembershipPlan } = useContextPlan();
  const [name, setName] = useState<string>("");
  const [totalPrice, setTotalPrice] = useState<string>("");

  const runForm = async () => {
    // setParams({name})
    // setParams({situation})
    // setParams({invoicing})
    // setParams({createdAt})
    // setParams({status})

    await createMembershipPlan({name, total_price: Number(totalPrice)});
    await getMembershipPlan();
    alert("Novo plano criado")
    setParams({ show: !show });
  };

  return (
    <React.Fragment>
      <Card
        flexDirection="column"
        marginTop={"10px"}
        w="100%"
        px="20px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Box pt={{ base: "130px", md: "60px", xl: "40px" }}>
          <FormControl rowGap="20px">
            {/* <SimpleGrid columns={2} spacing={10}> */}
            <FormLabel htmlFor="name">Nome do plano</FormLabel>
            <Input
              id="name"
              type="text"
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />

            <FormLabel htmlFor="totalPrice">Valor Mensal</FormLabel>
            <Input
              id="totalPrice"
              type="text"
              value={totalPrice}
              onChange={(event) => {
                setTotalPrice(event.target.value);
              }}
            />

            <Button
              onClick={() => {
                runForm();
              }}
            >
              Criar
            </Button>
            <Button onClick={() => setParams({ show: !show })}>Voltar</Button>
            {/* </SimpleGrid> */}
          </FormControl>
        </Box>
      </Card>
    </React.Fragment>
  );
}

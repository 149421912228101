import * as React from "react";
import { Box, Flex, Icon, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, } from "@chakra-ui/react";
import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import Card from "components/card/Card";
import { Order, OrderResponse } from "models/Order";
import { getOrderColor, getOrderStatus, getPaymentType, getPaymentTypeIcon } from 'helper/order';
import { formatDate, formatTime } from 'helper/time';
import { formatCurrency } from 'helper/currency';
import { ListOptionsMenu } from 'components/menu/ListOptionsMenu';
import ShowFilters from 'components/filter/ShowFilters';
import PaginationButtons from 'components/paginationButtons/PaginationButtons';
import OrderFilters from './OrderFilters';

const columnHelper = createColumnHelper<Order>();

type CheckTableProps = {
  tableData: OrderResponse
  tableName: string
  setFilters: Function
  showDetails: Function
  page: number
  setPage: Function
}

export default function CheckTable({ tableData, tableName, setFilters, showDetails, page, setPage }: CheckTableProps) {
  const [showFilters, setShowFilters] = React.useState<boolean>(false);

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const columns = [
    columnHelper.accessor("order_number", {
      id: "order_number",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Número do Pedido
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue() ?? "-"}
        </Text>
      ),
    }),
    columnHelper.accessor("shopify_order_number", {
      id: "shopify_order_number",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Pedido Shopify
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue() ?? "-"}
        </Text>
      ),
    }),
    columnHelper.accessor("transaction.type.name", {
      id: "transaction.type.name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Tipo
        </Text>
      ),
      cell: (info) => (
        <Icon
          as={getPaymentTypeIcon(info.getValue())}
          w='24px'
          h='auto'
          color='purple.600'
          title={getPaymentType(info.getValue())}
        />
      ),
    }),
    columnHelper.accessor("processed_at", {
      id: "processed_at",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Data da Venda
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {formatDate(info.getValue())} - {formatTime(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("total_price", {
      id: "total_price",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Valor
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {formatCurrency(parseFloat(info.getValue()))}
        </Text>
      ),
    }),
    columnHelper.accessor("customer.first_name", {
      id: "customer.first_name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Comprador
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue() || "-"}
        </Text>
      ),
    }),
    columnHelper.accessor("store.name", {
      id: "store.name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Loja
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("transaction.status.name", {
      id: "transaction.status.name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Status
        </Text>
      ),
      cell: (info) => (
        <Text color={getOrderColor(info.getValue())} fontSize="sm" fontWeight="700">
          {getOrderStatus(info.getValue())}
        </Text>
      ),
    }),
    {
      id: 'options',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'>
          Opções
        </Text>
      ),
      cell: (info: CellContext<Order, string>) => (
        <ListOptionsMenu data={info.row.original} showDetails={showDetails} />
      ),
    },
  ];

  const data = tableData?.data;
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          {tableName}
        </Text>
        <ShowFilters showFilters={showFilters} setShowFilters={setShowFilters} />
      </Flex>
      <OrderFilters showFilters={showFilters} setFilters={setFilters} />
      {data && (
        <Box>
          <Table
            variant="simple"
            color="gray.500"
            mb="24px"
            justifyContent="center"
          >
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        pe="10px"
                        borderColor={borderColor}
                        cursor="pointer"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        <Flex
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: "",
                            desc: "",
                          }[header.column.getIsSorted() as string] ?? null}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>

          <PaginationButtons
            onClick={setPage}
            currentPage={page}
            lastPage={tableData?.meta?.last_page}
          />

        </Box>
      )}
    </Card>
  );
}

import React from 'react';
import { Box, Tabs, TabList, TabPanels, Tab, TabPanel, useColorModeValue } from '@chakra-ui/react';
import PendingRequests from './components/PendingRequests';
import ProcessedRequests from './components/ProcessedRequests';
import { ContextProvider } from "./variables/localContext"


export default function MenuConsultas() {
	// Chakra Color Mode
	const brandColor = useColorModeValue('brand.500', 'white');
	const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

	return (
		<ContextProvider>
			<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
				<Tabs isLazy>
					<TabList>
						<Tab>Pedidos pendentes</Tab>
						<Tab>Pedidos processados</Tab>
					</TabList>
					<TabPanels>
						<TabPanel>
							<PendingRequests/>
						</TabPanel>
						<TabPanel>
							<ProcessedRequests/>
						</TabPanel>
					</TabPanels>
				</Tabs>

			</Box>
		</ContextProvider>
	);
}

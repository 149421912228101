import React from "react";
import { Container, Row, RowBody, Item, RowHeader, Header, LastItem, FirstItem } from "./styles";
import { Tag, Icon, Spinner } from "@chakra-ui/react";
import { Text } from "styleguide/Typograph";
import { getOrderColor, getOrderStatus, getPaymentType, getPaymentTypeIcon } from 'helper/order';
import { formatCurrency } from 'helper/currency';

interface listOrder {
    transaction_id: number;
    customer_name: string;
    store_name: string;
    payment_method: string;
    status: string;
    total_price: number;
}

const ListOrders = (props: { orders: listOrder[] }) => {
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if (props.orders.length > 0) {
            setLoading(false);
          }
        // setLoading(false);
    }, [props.orders]);

    return (
        <Container>
            <Header>
                <Item>
                    <Text type="h4">
                        Últimos pedidos
                    </Text>
                </Item>
            </Header>
            <Row>
                <RowHeader>
                    <FirstItem>
                        <Text type="h9">
                            Loja
                        </Text>
                    </FirstItem>
                    <Item>
                        <Text type="h9">
                            Método
                        </Text>
                    </Item>
                    <Item>
                        <Text type="h9">
                            Comprador
                        </Text>
                    </Item>
                    <Item>
                        <Text type="h9">
                            Status
                        </Text>
                    </Item>
                    <LastItem>
                        <Text type="h9">
                            Valor (R$)
                        </Text>
                    </LastItem>
                </RowHeader>
            </Row>
            { loading ?
                <Spinner/>
            :
                props.orders.map((order: listOrder) => (
                    <Row key={order.transaction_id}>
                        <RowBody>
                            <FirstItem>
                                <Text type="h6">
                                    {order.store_name}
                                </Text>
                            </FirstItem>
                            <Item>
                                <Icon
                                    as={getPaymentTypeIcon(order.payment_method)}
                                    w='24px'
                                    h='auto'
                                    color='purple.600'
                                    title={getPaymentType(order.payment_method)}
                                />
                            </Item>
                            <Item>
                                <Text type="h7">
                                    {order.customer_name}
                                </Text>
                            </Item>
                            <Item>
                                <Tag color={getOrderColor(order.status)} textAlign="center" size={"sm"}>
                                    {getOrderStatus(order.status)}
                                </Tag>
                            </Item>
                            <LastItem>
                                <Text type="h6">
                                    {formatCurrency(order.total_price)}
                                </Text>
                            </LastItem>
                        </RowBody>
                    </Row>
                ))
            }
        </Container>
    );
}

export default ListOrders;

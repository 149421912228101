import { Icon } from '@chakra-ui/react';
import { MdLock, MdOutlineShoppingCart, MdAppRegistration, MdEngineering, MdOutlinePayment, MdHelpCenter, MdLogoDev, MdDashboard } from 'react-icons/md';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import SignInCentered from 'views/auth/signIn';
import MainAcessos from 'views/admin/acessos';
import MainAuditoria from 'views/admin/auditoria';
import HelpDesk from 'views/admin/helpdesk';
import MenuVisaoLojista from 'views/admin/visaoLojista/Menu';
import PaymentPlan from 'views/admin/paymentPlans/Menu';
import Anticipations from 'views/admin/anticipations/Menu';
import OrdersMenu from './views/admin/orders';
import Reports from 'views/admin/reports';
import AccountsBalanceMenu from 'views/admin/accountsBalance/AccountsBalanceMenu';
import Dashboard from 'views/admin/dashboard';

const routes = [
	{
		name: 'Visão de lojistas',
		layout: '/admin',
		path: '/visao-lojista',
		icon: <Icon as={MdAppRegistration} width='20px' height='20px' color='inherit' />,
		component: MenuVisaoLojista,
	},
	{
		name: 'Gerenciar cadastros',
		layout: '/admin',
		path: '/acesso',
		icon: <Icon as={MdEngineering} width='20px' height='20px' color='inherit' />,
		component: MainAcessos,
	},
	{
		name: 'Dashboard',
		layout: '/admin',
		path: '/dashboard',
		icon: <Icon as={MdDashboard} width='20px' height='20px' color='inherit' />,
		component: Dashboard,
	},
	{
		name: 'Pedidos de Ajuda',
		layout: '/admin',
		path: '/helpdesk',
		icon: <Icon as={MdHelpCenter} width='20px' height='20px' color='inherit' />,
		component: HelpDesk,
	},
	{
		name: 'Planos',
		layout: '/admin',
		path: '/planos',
		icon: <Icon as={MdOutlinePayment} width='20px' height='20px' color='inherit' />,
		component: PaymentPlan,
	},
	{
		name: 'Solicitação recebíveis',
		layout: '/admin',
		path: '/recebiveis',
		icon: <Icon as={MdOutlinePayment} width='20px' height='20px' color='inherit' />,
		component: Anticipations,
	},
	{
		name: 'Pagamentos',
		layout: '/admin',
		path: '/account-balance',
		icon: <Icon as={RiMoneyDollarCircleFill} width='20px' height='20px' color='inherit' />,
		component: AccountsBalanceMenu,
	},
	{
		name: 'Pedidos',
		layout: '/admin',
		path: '/orders',
		icon: <Icon as={MdOutlineShoppingCart} width="20px" height="20px" color="inherit"/>,
		component: OrdersMenu,
	},
	// {
	// 	name: 'Produtos',
	// 	layout: '/admin',
	// 	path: '/pagamentos',
	// 	icon: <Icon as={MdOutlinePayment} width='20px' height='20px' color='inherit' />,
	// 	component: MenuPagamentos,
	// },
	// {
	// 	name: 'Geren. Cadastros',
	// 	layout: '/admin',
	// 	path: '/configuracoes',
	// 	icon: <Icon as={MdEngineering} width='20px' height='20px' color='inherit' />,
	// 	component: MenuConfiguracoes,
	// },
	// {
	// 	name: 'Pagamentos',
	// 	layout: '/admin',
	// 	path: '/configuracoes',
	// 	icon: <Icon as={MdEngineering} width='20px' height='20px' color='inherit' />,
	// 	component: MenuConfiguracoes,
	// },
	// {
	// 	name: 'Api Query',
	// 	layout: '/admin',
	// 	path: '/configuracoes',
	// 	icon: <Icon as={MdEngineering} width='20px' height='20px' color='inherit' />,
	// 	component: MenuConfiguracoes,
	// },
	// {
	// 	name: 'Config. Credenciais',
	// 	layout: '/admin',
	// 	path: '/configuracoes',
	// 	icon: <Icon as={MdEngineering} width='20px' height='20px' color='inherit' />,
	// 	component: MenuConfiguracoes,
	// },

	{
		name: 'Auditoria',
		layout: '/admin',
		path: '/auditoria',
		icon: <Icon as={MdLogoDev} width='20px' height='20px' color='inherit' />,
		component: MainAuditoria,
	},
	{
		name: 'Relatórios',
		layout: '/admin',
		path: '/reports',
		icon: <Icon as={HiOutlineDocumentReport} width='20px' height='20px' color='inherit' />,
		component: Reports,
	},
	{
		name: 'Sign In',
		layout: '/auth',
		path: '/sign-in',
		icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
		component: SignInCentered
	}
];

export default routes;

import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { formatCurrency } from 'helper/currency';

type MoneyContainerProps = {
	title: string
	value: number
};

export default function MoneyContainer({ title, value }: MoneyContainerProps) {
	return (
		<Box>
			<Text fontSize="18px" mt="10px" textColor="#1b254b">{title}</Text>
			<Text fontSize="22px" fontWeight="bold">{formatCurrency(value, 2)}</Text>
		</Box>
	);
}

import React from 'react';
import { ProviderAccount, ProviderAccountStatus, ProviderKey } from 'models/ProviderAccount';
import { Button, Flex, Tag, TagLeftIcon, Text } from '@chakra-ui/react';
import StatusTag from 'views/admin/acessos/components/StatusTag';
import { migrateProviderAccount } from 'services/PaymentsApi/providerAccounts';
import { notifyError, notifySuccess } from 'utils/notification';
import { FaUserAlt } from 'react-icons/fa';
import { SellerPaymentType } from 'models/SellerPaymentType';
import { PaymentMethodKey } from 'models/PaymentMethod';
import PaymentMethodDetails from 'views/admin/acessos/components/PaymentMethodDetails';
import LoadingPage from 'components/loading/LoadingPage';

type ProviderAccountProps = {
  providerAccount: ProviderAccount,
  userId: string,
  refetch: Function,
  sellerPaymentTypes: SellerPaymentType[] | null
  refetchSellerPaymentTypes: Function
}

export default function ProviderAccountDetails({ providerAccount, userId, refetch, sellerPaymentTypes, refetchSellerPaymentTypes }: ProviderAccountProps) {
  const [loading, setLoading] = React.useState(false);

  const migrateProvider = async () => {
    try {
      setLoading(true);
      await migrateProviderAccount(userId, providerAccount.provider.key);
      notifySuccess(`Conta de provedor migrada para ${providerAccount.provider.name} com sucesso!`);
      refetch();
      refetchSellerPaymentTypes();
    } catch (e) {
      notifyError(`Erro ao migrar para ${providerAccount.provider.name}.`);
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Flex borderWidth="1px" borderRadius="10px" padding="10px 5px" direction="column">
      <LoadingPage isOpen={loading} />
      <Flex justify="space-between" align="center">
        <Flex align="center">
          <StatusTag status={providerAccount.status} />
          <Flex ml="8px" align="center">
            <Text fontSize="18px">{providerAccount.provider.name}</Text>
            <Tag fontStyle="italic" fontSize="12px" ml="10px" variant="outline">
              <TagLeftIcon boxSize='12px' as={FaUserAlt} />
              {providerAccount.account_id}
            </Tag>
          </Flex>
        </Flex>
        {providerAccount.status === ProviderAccountStatus.ACTIVE && ![ProviderKey.K8BANK, ProviderKey.IUGU].includes(providerAccount.provider.key) && (
          <Button colorScheme="purple" onClick={migrateProvider}>Migrar</Button>
        )}
      </Flex>
      {providerAccount.status === ProviderAccountStatus.ACTIVE && (
        <Flex mt="10px" direction="column">
          <Text fontSize="16px" fontWeight="bold">Formas de Pagamento</Text>
          {Object.values(PaymentMethodKey).map((value: PaymentMethodKey) =>
            <PaymentMethodDetails
              userId={userId}
              paymentMethod={value}
              providerAccount={providerAccount}
              sellerPaymentTypes={sellerPaymentTypes}
              refetchSellerPaymentTypes={refetchSellerPaymentTypes}
            />)
          }
        </Flex>
      )}
    </Flex>
  );
}

import { Response } from './Request';

export interface ChargebackAlertResponse extends Response<ChargebackAlert[]> {}

export type ChargebackAlert = {
  id: number;
  alert_id: string;
  type: ChargebackAlertType;
  alert_date?: string;
  hours_from_transaction?: string;
  issuer?: string;
  card_number?: string;
  card_bin: string;
  card_last4: string;
  arn?: string;
  transaction_date: string;
  merchant_descriptor?: string;
  member_id?: number;
  mcc?: string;
  transaction_amount: number;
  currency: string;
  transaction_type?: string;
  initiated_by?: string;
  is_3d_secure?: string;
  source?: string;
  auth_code?: string;
  merchant_member_name?: string;
  transaction_id?: string;
  chargeback_reason_code?: string;
  chargeback_amount?: number;
  chargeback_currency?: string;
}

export enum ChargebackAlertType {
  FRAUD = 'issuer_alert',
  CHARGEBACK = 'customerdispute_alert',
}

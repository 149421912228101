import { MdPix } from 'react-icons/md';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import { BsDash, BsFillCreditCardFill } from 'react-icons/bs';

export function getOrderStatus(status: string) {
  switch (status) {
    case "failed":
      return "Falha";
    case "pending":
      return "Aguardando Pagamento";
    case "completed":
      return "Pagamento Aprovado";
    case "reversed":
      return "Transação Revertida";
    case "cancelled":
      return "Cancelado";
    case "chargeback":
      return "Transação Contestada";
    default:
      return "-";
  }
}

export function getOrderColor(status: string) {
  switch (status) {
    case "failed":
      return "#da2c38";
    case "pending":
      return "#ffb703";
    case "completed":
      return "#29bf12";
    case "reversed":
      return "#da2c38";
    case "cancelled":
      return "#da2c38";
    case "chargeback":
      return "#da2c38";
    default:
      return "#000";
  }
}

export function getPaymentType(status: string) {
  switch (status) {
    case "debit":
      return "Débito";
    case "credit":
      return "Cartão de Crédito";
    case "pix":
      return "Pix";
    case "invoice":
      return "Boleto";
    default:
      return "...";
  }
}

export function getPaymentTypeIcon(status: string) {
  switch (status) {
    case "debit":
      return BsFillCreditCardFill;
    case "credit":
      return BsFillCreditCardFill;
    case "pix":
      return MdPix
    case "invoice":
      return FaFileInvoiceDollar
    default:
      return BsDash;
  }
}

import { adminApi } from "./api";
import { useUserStore } from 'store/reducers/user';
import { CreateRecipient } from "models/Payments"
export const createRecipientOnGatewayProvider = async (params: CreateRecipient.Params) => {
    const token = useUserStore.getState().auth.token;
    try {

        const response = await adminApi.post(
            `${process.env.REACT_APP_API_URL}/api/stone?provider_key=${params.provider_key}`,
            params,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        );

        return {
            success: true,
            data: response.data
        }
    } catch (err) {
        return {
            success: true,
            message: "Erro ao instalar"
        }
    }
}
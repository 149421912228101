import { paymentsApi } from "./api";
import { OrderReport, SellerReport, TransactionsReport } from 'models/report';

export const sellerReports = async (providerKey: string): Promise<SellerReport[]> => {
  const url = `/reports/sellers?provider_key=${providerKey}`;

  const response = await paymentsApi.get(url);

  return response.data;
};

export const ordersReport = async (providerKey: string): Promise<OrderReport[]> => {
  const url = `/reports/orders?provider_key=${providerKey}`;

  const response = await paymentsApi.get(url);

  return response.data;
}

export const transactionsReport = async (sellerId: number): Promise<TransactionsReport> => {
  const url = `/reports/seller_transactions?seller_id=${sellerId}`;

  const response = await paymentsApi.get(url);

  return response.data;
};

import React from 'react';
import { Box, Tooltip } from '@chakra-ui/react';

type StatusTagProps = {
  status: 'active' | 'disabled' | 'pending'
  size?: string
}

export default function StatusTag ({ status, size = '20px' }: StatusTagProps) {
  const getColor = () => {
    switch (status) {
      case 'active':
        return "#01B574";
      case 'disabled':
        return "#EE5D50";
      case 'pending':
        return "#FFC043";
      default:
        return "#FFC043";

    }
  }

  const getLabel = () => {
    switch (status) {
      case 'active':
        return "Ativa";
      case 'disabled':
        return "Inativa";
      case 'pending':
        return "Pendente";
      default:
        return "Pendente";

    }
  }

  return (
    <Tooltip label={getLabel()}>
      <Box
        backgroundColor={getColor()}
        width={size}
        height={size}
        borderRadius="50%"
      >
      </Box>
    </Tooltip>
  );
};

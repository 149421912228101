import {
  createContext,
  ReactNode,
  useState,
  useCallback,
  useContext,
} from "react";

interface ProviderProps {
  children: ReactNode;
}

type Params = {
  page?: number;
  limit?: number;
  situation?: string;
  invoicing?: string;
  createdAt?: string;
  name?: string;
  show?: boolean;
  totalPages?: number;
  status?: "0" | "1" | "-";
};
interface ContextProps {
  page: number;
  limit: number;
  situation: string;
  invoicing: string;
  createdAt: string;
  name: string;
  setParams: (params: Params) => void;
  show?: boolean;
  totalPages?: number;
  status?: "0" | "1" | "-";
}

export const ContextUserData = createContext<ContextProps | null>(null);

export function ContextProvider({ children }: ProviderProps) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [situation, setSituation] = useState("");
  const [invoicing, setInvoicing] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [name, setName] = useState("");
  const [show, setShow] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [status, setStatus] = useState<"0" | "1" | "-">("-");

  const setParams = (params: Params) => {
    console.log(params);
    if (params.page) setPage(params.page);
    if (params.limit) setLimit(params.limit);
    if (params.situation) setSituation(params.situation);
    if (params.invoicing) setInvoicing(params.invoicing);
    if (params.createdAt) setCreatedAt(params.createdAt);
    if (params.name || params.name === "") setName(params.name);
    if (typeof params.totalPages === 'number') {
      console.log("entrou no totalpage");
      setTotalPages(params.totalPages);
    }
    if (params.status) setStatus(params.status);

    if (params.show === true || params.show == false) {
      setShow(params.show);
    }
  };

  const contextValue: ContextProps = {
    page,
    limit,
    situation,
    invoicing,
    createdAt,
    name,
    setParams,
    show,
    totalPages,
    status,
  };

  console.log({ contextValue });
  return (
    <ContextUserData.Provider value={contextValue}>
      {children}
    </ContextUserData.Provider>
  );
}

export function useContextLocal() {
  const context = useContext(ContextUserData);

  return context;
}

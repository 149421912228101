import axios from 'axios'


export const paymentsApi = axios.create({
    baseURL: process.env.REACT_APP_PAYMENTS_URL,
    headers: {
        'Authorization': process.env.REACT_APP_PAYMENTS_AUTH_TOKEN,
    }
})


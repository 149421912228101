import React from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { ChargebackAlert, ChargebackAlertType } from 'models/ChargebackAlert';
import { formatDate, formatTime } from 'helper/time';
import DetailsText from 'components/details/DetailsText';
import { formatCurrency } from 'helper/currency';

type ChargebackAlertDetailsProps = {
	selectedAlert: ChargebackAlert;
	setSelectedAlert: Function;
}

type DetailsProps = {
	title: string;
	value: string | number;
	money?: boolean;
}

const Details = ({ title, value, money = false }: DetailsProps) => {
	if (!value) return null;

	let detailValue: string;

	if (money) {
		detailValue = formatCurrency(value as number, 2);
	} else {
		detailValue = value as string;
	}

	return (
		<Box flexBasis={{base: '50%', md: '33%'}}>
			<DetailsText title={title} value={detailValue}/>
		</Box>
	);
};

export default function ChargebackAlertDetails({ selectedAlert, setSelectedAlert }: ChargebackAlertDetailsProps) {
	return (
		<>
			<Button onClick={() => setSelectedAlert(null)}>
				Voltar
			</Button>
			<Flex backgroundColor="white" px="10px" py="20px" borderRadius="20px" mt="20px" direction={{ base: 'column', lg: 'row' }}>
				<Box px="10px">
					<Box mb="20px">
						<Text
							color="secondaryGray.900"
							fontSize="22px"
							fontWeight="700"
							lineHeight="100%"
						>
							Tipo do Alerta: {selectedAlert.type === ChargebackAlertType.CHARGEBACK ? "Chargeback" : "Fraude"}
						</Text>
						<Text>id: {selectedAlert.alert_id}</Text>
					</Box>
					<Flex flexWrap="wrap">
						<Details title="Data do Alerta" value={`${formatDate(selectedAlert.alert_date)} - ${formatTime(selectedAlert.alert_date)}`} />
						<Details title="Data da Transação" value={`${formatDate(selectedAlert.transaction_date)} - ${formatTime(selectedAlert.transaction_date)}`} />
						<Details title="Horas desde a Transação" value={`${selectedAlert.hours_from_transaction} horas`} />
						<Details title="Número do Cartão" value={selectedAlert.card_number} />
						<Details title="Primeiros 6 dígitos" value={selectedAlert.card_bin} />
						<Details title="Últimos 4 dígitos" value={selectedAlert.card_last4} />
						<Details title="Valor da Transação" value={selectedAlert.transaction_amount} money />
						<Details title="Valor do Chargeback" value={selectedAlert.chargeback_amount} money />
						<Details title="Tipo de Transação" value={selectedAlert.transaction_type} />
						<Details title="Emissor" value={selectedAlert.issuer} />
						<Details title="Descriptor" value={selectedAlert.merchant_descriptor} />
						<Details title="Nome do Merchant" value={selectedAlert.merchant_member_name} />
						<Details title="Motivo do Chargeback" value={selectedAlert.chargeback_reason_code} />
						<Details title="Iniciado Por" value={selectedAlert.initiated_by} />
						<Details title="Fonte" value={selectedAlert.source} />
						<Details title="Id da Transação" value={selectedAlert.transaction_id} />
						<Details title="Arn" value={selectedAlert.arn} />
						<Details title="Mcc" value={selectedAlert.mcc} />
					</Flex>
				</Box>
			</Flex>
		</>
	);
}

import {
  createContext,
  ReactNode,
  useState,
  useCallback,
  useContext,
} from "react";
import { RowObj } from "../views/admin/visaoLojista/models/rowUserObj";

import { getToken, GetToken } from "../services/AdminApi/auth";
import {
  getPaymentAdvanceRequest,
  getProcessedPaymentAdvanceRequest,
  GetPaymentAdvanceRequest,
  PaymentAdvanceRequest,
  approvePaymentAdvanceRequest,
  cancelPaymentAdvanceRequest,
  ApprovePaymentAdvanceRequest,
  CancelPaymentAdvanceRequest,
} from "../services/AdminApi/paymentAdvanceRequest";

interface ProviderProps {
  children: ReactNode;
}

interface ContextProps {
  getPendingRequests: () => Promise<GetPaymentAdvanceRequest.Result>;
  getProcessedRequests: () => Promise<GetPaymentAdvanceRequest.Result>
  paymentRequest: PaymentAdvanceRequest[];
  processedRequests: PaymentAdvanceRequest[];
  approvePaymentRequest: (
    params: ApprovePaymentAdvanceRequest.Params
  ) => Promise<ApprovePaymentAdvanceRequest.Result>;
  cancelPaymentRequest: (
    params: CancelPaymentAdvanceRequest.Params
  ) => Promise<CancelPaymentAdvanceRequest.Result>;
  loading: boolean;
}

export const ContextUserData = createContext<ContextProps | null>(null);

export function PaymentRequestContextProvider({ children }: ProviderProps) {
  const [paymentRequest, setPaymentRequest] = useState<PaymentAdvanceRequest[]>(
    []
  );
  const [processedRequests, setProcessedRequests] = useState<PaymentAdvanceRequest[]>(
    []
  );
  const [loading, setLoading] = useState(false);

  const approvePaymentRequest = useCallback(
    async (params: ApprovePaymentAdvanceRequest.Params) => {
      try {
        const response: ApprovePaymentAdvanceRequest.Result =
          await approvePaymentAdvanceRequest({ idAdvance: params.idAdvance });

        return response;
      } catch (err) {
        return {
          success: false,
          data: 0
        }
      }
    },
    []
  );

  const cancelPaymentRequest = useCallback(
    async (params: CancelPaymentAdvanceRequest.Params) => {
      try {
        const response: CancelPaymentAdvanceRequest.Result =
          await cancelPaymentAdvanceRequest({ idAdvance: params.idAdvance });

        return response;
      } catch (err) {
        return {
          success: false,
          data: 0
        }
      }
    },
    []
  );

  const getPendingRequests = useCallback(async () => {
    try {
      setLoading(false);
      const response: GetPaymentAdvanceRequest.Result =
        await getPaymentAdvanceRequest();

      setPaymentRequest(response.data);

      return response;
    } catch (err: any) {
      setLoading(false);
      return {
        data: [],
        success: false,
      };
    } finally {
      setLoading(true);
    }
  }, []);

  const getProcessedRequests = useCallback(async () => {
    try {
      setLoading(false);
      const response: GetPaymentAdvanceRequest.Result =
        await getProcessedPaymentAdvanceRequest();

        setProcessedRequests(response.data);

      return response;
    } catch (err: any) {
      setLoading(false);
      return {
        data: [],
        success: false,
      };
    } finally {
      setLoading(true);
    }
  }, []);

  const contextValue: ContextProps = {
    getProcessedRequests,
    processedRequests,
    getPendingRequests,
    paymentRequest,
    loading,
    approvePaymentRequest,
    cancelPaymentRequest
  };

  return (
    <ContextUserData.Provider value={contextValue}>
      {children}
    </ContextUserData.Provider>
  );
}

export function useContextPlan() {
  const context = useContext(ContextUserData);

  return context;
}

import { create } from "zustand";
import { persist } from "zustand/middleware";
import { useProductStore } from "../products";

interface IUser {
  id: number;
  name: string;
  email: string;
  role: { id: number; name: string };
}

interface IAuthentication {
  auth: {
    user?: IUser;
    token?: string;
    tokenShopify?: string;
  };
  updateToken: (newToken: string) => void;
  updateUser: (user: IUser) => void;
  updateTokenShopify: (newTokenShopify: string) => void;
  logout: () => void;
}

const clearProductsData = useProductStore.getState().clearProductsData;

export const useUserStore = create<IAuthentication>()(
  persist(
    (set) => ({
      auth: {},
      updateToken: (newToken: string) =>
        set((state) => ({ auth: { ...state.auth, token: newToken } })),
      updateUser: (user) =>
        set((state) => ({
          auth: {
            ...state.auth,
            user,
          },
        })),
      updateTokenShopify: (newTokenShopify: string) =>
        set((state) => ({ auth: { ...state.auth, tokenShopify: newTokenShopify } })),
      logout: () => {
        useUserStore.persist.clearStorage();
        clearProductsData();
      },
    }),
    {
      name: "user-credential",
    }
  )
);

import { PaymentMethodKey } from 'models/PaymentMethod';

export function getPaymentMethod(paymentMethodKey: PaymentMethodKey) {
  switch (paymentMethodKey) {
    case "credit":
      return "Cartão de Crédito";
    case "pix":
      return "Pix";
    case "invoice":
      return "Boleto";
    default:
      return "...";
  }
}

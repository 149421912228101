import React from 'react';
import { Box, SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs, useColorModeValue } from '@chakra-ui/react';
import PaymentPlans from './components/PaymentPlans';
import { ContextProvider } from "./variables/localContext"


export default function MenuConsultas() {
	// Chakra Color Mode
	const brandColor = useColorModeValue('brand.500', 'white');
	const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

	return (
		<ContextProvider>
			<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
				{/* <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'> */}
					<PaymentPlans />
				{/* </SimpleGrid> */}
			</Box>
		</ContextProvider>
	);
}

import React from 'react';
import { Box, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { formatCurrency } from 'helper/currency';
import { SellersBalanceResponse } from 'models/SellerBalance';
import { BeatLoader } from "react-spinners";
import {
	Button,
	FormControl,
	FormLabel,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Spinner,
	Flex
} from '@chakra-ui/react';
import MoneyInput from 'components/moneyInput/MoneyInput';
import { useForm } from 'react-hook-form';

type SellersBalanceTableProps = {
	sellersResponse: SellersBalanceResponse;
	page: number;
	setPage: Function;
	setFilters: Function;
	setSelectedSeller: Function;
}

export default function Transfer() {
	const [loading, setLoading] = React.useState<boolean>(false);
	const [modalOpen, setModalOpen] = React.useState<boolean>(false);
	const [data, setData] = React.useState([]);
	const [selectTransfer, setSelectTransfer] = React.useState(null);

  const {
		watch,
		handleSubmit,
		setValue,
	} = useForm();
  const amountValue = watch('amount');
  
  React.useEffect(() => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_PAYMENTS_URL}/marketplaces`, {
      method: "GET",
      headers: {
        "Authorization": process.env.REACT_APP_PAYMENTS_AUTH_TOKEN,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    })
      .then(response => response.json())
      .then(data => {
        setData(data);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      }); 
      
  }, []);

  function handleSelectTransfer(data: any) {
    setModalOpen(true);
    setSelectTransfer(data);
  }

  async function handleTransfer(data: any) {
  if (selectTransfer.balance_available_for_withdraw < parseInt(data.amount.replace(",",".").replace(".", "")) ||
    selectTransfer.balance < parseInt(data.amount.replace(",",".").replace(".", ""))
  ) {
    alert('Transferencia não permitida, valor maior do que o disponivel');
    setModalOpen(false);
    return;
  }
  
  if (selectTransfer.balance_available_for_withdraw <= 0 || selectTransfer.balance <= 0) {
    alert('Transferencia não permitida ');
    setModalOpen(false);
    return;
  }

    setModalOpen(true);
    setSelectTransfer(data);

    const payload = {
      value: parseInt(data.amount.replace(",",".").replace(".", "")),
      id: selectTransfer.id
    }

    fetch(`${process.env.REACT_APP_PAYMENTS_URL}/transfer`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Authorization": process.env.REACT_APP_PAYMENTS_AUTH_TOKEN,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    })
      .then(response => response.json())
      .then(data => {
        setModalOpen(false);
        alert('Transferencia Solicitada, atualize a pagina.');
      })
      .catch(error => {
        setModalOpen(false);
        alert('Erro ao realizar transferencia.');
      }); 
  }

  function handleCloseModal () {
    setModalOpen(false);
    setSelectTransfer({});
    setValue('amount', '');
  }

  if (loading) {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <BeatLoader color="#00CF79" />
      </div>
    )
  }

	return (
		<>
			<Box backgroundColor="white" px="10px" py="20px" borderRadius="20px" mt="20px">
				<TableContainer>
					<Table variant='simple'>
						<Thead>
							<Tr>
								<Th>ID</Th>
								<Th>Nome</Th>
								<Th>Saldo Disponivel</Th>
								<Th>Saldo</Th>
								<Th>Bloqueado</Th>
								<Th>A Receber</Th>
								<Th>Transferir</Th>
							</Tr>
						</Thead>
						<Tbody>
							{data && data.map(transfer => (
								<Tr key={transfer.id} _hover={{ backgroundColor: '#efefef' }} cursor="pointer">
									<Td width={25}>{transfer.id}</Td>
									<Td>{transfer.name}</Td>
									<Td width={25}>{formatCurrency(transfer.balance_available_for_withdraw, 2)}</Td>
									<Td>{formatCurrency(transfer.balance, 2)}</Td>
									<Td>{formatCurrency(transfer.balance_blocked, 2)}</Td>
									<Td>{formatCurrency(transfer.balance_receivable, 2)}</Td>
									<Td><Button colorScheme="green" onClick={() => handleSelectTransfer(transfer)}>Transferir</Button></Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</TableContainer>
			</Box>

			<Modal isOpen={modalOpen} onClose={handleCloseModal}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Transferir Valores</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
          <form onSubmit={handleSubmit(handleTransfer)}>
            <FormControl>
              <FormLabel htmlFor="amount">R$ Valor Desejado</FormLabel>
              <MoneyInput id="amount" value={amountValue} setValue={setValue} />
              {/* <Text fontSize="12px" my="5px" fontStyle="italic">Obs: Como a antecipação é feita pela soma dos valores dos pedidos, nem sempre o valor desejado será o valor que de fato poderá ser antecipado.</Text> */}
              <Box display="flex" justifyContent="right">
                <Button type='submit' colorScheme="green" my="10px">
                  Realizar Transferencia
                </Button>
              </Box>
            </FormControl>
          </form>
				</ModalBody>
			</ModalContent>
		</Modal>
		</>
	);
}

import React from 'react';
import { Box, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { formatCurrency } from 'helper/currency';
import PaginationButtons from 'components/paginationButtons/PaginationButtons';
import { ChargebackAlertResponse, ChargebackAlertType } from 'models/ChargebackAlert';
import { formatDate, formatTime } from 'helper/time';

type ChargebackAlertsTableProps = {
	alertsResponse: ChargebackAlertResponse;
	page: number;
	setPage: Function;
	setSelectedAlert: Function;
}

export default function ChargebackAlertsTable({ alertsResponse, page, setPage, setSelectedAlert }: ChargebackAlertsTableProps) {
	return (
		<Box backgroundColor="white" px="10px" py="20px" borderRadius="20px" mt="20px">
			<Box display="flex" justifyContent="space-between" alignContent="center" mb="20px" px="10px">
				<Text
					color="secondaryGray.900"
					fontSize="22px"
					fontWeight="700"
					lineHeight="100%"
				>
					Alertas de Chargeback
				</Text>
			</Box>
			<TableContainer>
				<Table variant='simple'>
					<Thead>
						<Tr>
							<Th>Tipo</Th>
							<Th>Data do Alerta</Th>
							<Th>Data da Transação</Th>
							<Th>Valor</Th>
							<Th>Número do Cartão</Th>
							<Th>Descriptor</Th>
						</Tr>
					</Thead>
					<Tbody>
						{alertsResponse && alertsResponse.data.map(alert => (
							<Tr key={alert.id} _hover={{ backgroundColor: '#efefef' }} cursor="pointer" onClick={() => setSelectedAlert(alert)}>
								<Td>{alert.type === ChargebackAlertType.CHARGEBACK ? "Chargeback" : "Fraude"}</Td>
								<Td>{formatDate(alert.alert_date)} - {formatTime(alert.alert_date)}</Td>
								<Td>{formatDate(alert.transaction_date)} - {formatTime(alert.transaction_date)}</Td>
								<Td>{formatCurrency(alert.transaction_amount, 2)}</Td>
								<Td>{alert.card_number}</Td>
								<Td>{alert.merchant_descriptor}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
				<Box display="flex" alignItems="center" mt="20px">
					<PaginationButtons onClick={setPage} currentPage={page} lastPage={alertsResponse?.meta?.last_page} />
				</Box>
			</TableContainer>
		</Box>
	);
}

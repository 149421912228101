import {
	Flex,
	Box,
	Table,
	Checkbox,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useColorModeValue,
	Button
} from '@chakra-ui/react';
import * as React from 'react';

import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable
} from '@tanstack/react-table';
import {useContextUser} from 'hooks/contextApi'
// Custom components
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';

type RowObj = {
	name: string;
	whatsapp: string;
	cidade: string;
	dataCadastro: string;
	status: string;
	qtdLojas: number;
	situacao: 'Ativo' | 'Ativo e operante' | 'Ativo e inoperante' | 'Inativo';
	faturamento: string;
	dataSaida: string;
};

const columnHelper = createColumnHelper<RowObj>();

// const columns = columnsDataCheck;
export default function CheckTable(props: { tableData: any, tableName: any, table: any }) {
	const { tableData, tableName, table } = props;
	const {totalResult} = useContextUser();
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');


	const [data, setData] = React.useState(() => [...props.tableData]);

	return (
		<Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
				<Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
					{tableName}
				</Text>
				<Text color={textColor} fontSize='14px' fontWeight='700' lineHeight='100%'>
					Total de resultados {totalResult}
				</Text>
				{/* <Menu /> */}
			</Flex>
			<Box>
				<Table variant='simple' color='gray.500' mb='24px' mt="12px">
					<Thead>
						{table.getHeaderGroups().map((headerGroup: any) => (
							<Tr key={headerGroup.id}>
								{headerGroup.headers.map((header: any) => {
									return (
										<Th
											key={header.id}
											colSpan={header.colSpan}
											pe='10px'
											borderColor={borderColor}
											cursor='pointer'
											onClick={header.column.getToggleSortingHandler()}>
											<Flex
												justifyContent='space-between'
												align='center'
												fontSize={{ sm: '10px', lg: '12px' }}
												color='gray.400'>
												{flexRender(header.column.columnDef.header, header.getContext())}{{
													asc: '',
													desc: '',
												}[header.column.getIsSorted() as string] ?? null}
											</Flex>
										</Th>
									);
								})}
							</Tr>
						))}
					</Thead>
					<Tbody>
						{table.getRowModel().rows.map((row: any) => {
							return (
								<Tr key={row.id}>
									{row.getVisibleCells().map((cell: any) => {
										return (
											<Td
												key={cell.id}
												fontSize={{ sm: '14px' }}
												minW={{ sm: '150px', md: '200px', lg: 'auto' }}
												borderColor='transparent'>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</Td>
										);
									})}
								</Tr>
							);
						})}
					</Tbody>
				</Table>
				
			</Box>
		</Card>
	);
} 
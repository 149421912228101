import { adminApi } from "./api";

export const getAllMembershipPlans = async (
  
): Promise<GetAllMembershipPlans.Result> => {
  const adminUrl = `/api/admin/membership-plans`;
  try {
    // const users = await adminApi.get(adminUrl);

    const param: GetAllMembershipPlans.Params = {
     
    };

    const membershipPlans = await adminApi.get(
      `${process.env.REACT_APP_API_URL}${adminUrl}`,
    );

    return membershipPlans.data as GetAllMembershipPlans.Result;
  } catch (err) {
    console.log(err);
  }
};

export const createMembershipPlans = async (
    params: CreateMembershipPlans.Params
    ): Promise<CreateMembershipPlans.Result> => {
      const adminUrl = `/api/admin/membership-plan`;
      try {
        // const users = await adminApi.get(adminUrl);
    
        const param: GetAllMembershipPlans.Params = {
         
        };
    
        const membershipPlans = await adminApi.post(
          `${process.env.REACT_APP_API_URL}${adminUrl}`,
          {
            name: params.name,
            totalPrice: params.total_price
          }
        );
    
        return membershipPlans.data as CreateMembershipPlans.Result;
      } catch (err) {
        console.log(err);
      }
    };
    export const updateMembershipPlans = async (
      params: UpdateMembershipPlan.Params
      ): Promise<UpdateMembershipPlan.Result> => {
        const adminUrl = `/api/admin/membership-plan/${params.id}`;
        try {
          // const users = await adminApi.get(adminUrl);
      
          const membershipPlans = await adminApi.put(
            `${process.env.REACT_APP_API_URL}${adminUrl}`,
            {
              status: params.status,
              totalPrice: params.totalPrice
            }
          );
      
          return membershipPlans.data as CreateMembershipPlans.Result;
        } catch (err) {
          console.log(err);
        }
      };

    export namespace UpdateMembershipPlan {
      export type Result = {
        data: MembershipPlan;
  
      };
    
      export type Params = {
        id: number;
        status?: boolean;
        totalPrice?: number;
      };
    }
export namespace GetAllMembershipPlans {
  export type Result = {
    data: MembershipPlan[];
    total: number;
  };

  export type Params = {
    status?: boolean;
    situation?: string;
    name?: string;
    invoicing?: string;
    page?: number;
    limit?: number;
    createdAt?: string;
  };
}

export namespace CreateMembershipPlans {
    export type Result = {
      data: MembershipPlan;
      message: number;
    };
  
    export type Params = Pick<MembershipPlan, "name" | "total_price">
  }

export type MembershipPlan = {
  id: number;
  name: string;
  status: boolean;
  total_price: number;
  created_at: Date;
  updated_at: Date;
};

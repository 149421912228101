import React from 'react';
import {
	Box,
	Heading,
} from '@chakra-ui/react';
import { ProviderBalance } from 'models/ProviderBalance';
import { capitalizeFirstLetter } from 'helper/formatters';
import MoneyContainer from 'views/admin/accountsBalance/components/ProvidersBalance/components/MoneyContainer';

type ProviderBalanceProps = {
	providerBalance: ProviderBalance;
};

export default function ProviderBalanceContainer({ providerBalance }: ProviderBalanceProps) {
	return (
		<Box py="20px" px="30px" backgroundColor="white" m="10px" borderRadius="20px" width="300px">
			<Heading mb="20px" textColor="#1b254b">{capitalizeFirstLetter(providerBalance.provider)}</Heading>
			<MoneyContainer title="Saldo Disponível" value={providerBalance.balance} />
			<MoneyContainer title="Lançamentos Futuros" value={providerBalance.waiting_funds} />
			{
				providerBalance.total_withdrawn !== null && (
					<>
						<MoneyContainer title="Total Sacado" value={providerBalance.total_withdrawn} />
					</>
				)
			}
		</Box>
	);
}

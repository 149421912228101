type Filter = {
  key: string
  value: string
}

export function filtersToUrl(filters: any): string {
  const activeFilters: Filter[] = cleanFilters(filters);
  let url = "";
  for (let i = 0; i < activeFilters.length; i++) {
    url += `&${activeFilters[i].key}=${activeFilters[i].value}`
  }
  return url;
}

function cleanFilters(filters: any): Filter[] {
  const activeFilters = [];
  for (const key in filters) {
    if (filters[key] && filters[key] !== "") {
      activeFilters.push({
        key,
        value: filters[key]
      });
    }
  }
  return activeFilters;
}

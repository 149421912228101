import { paymentsApi } from 'services/PaymentsApi/api';

export async function chargeback(orderId: number) {
  if (!orderId) throw new Error('Order id is required');

  const url = `/chargeback/${orderId}`;

  const response = await paymentsApi.delete(url);
  return response.data;
}

import { paymentsApi } from "./api";
import { ChargebackAlertResponse } from 'models/ChargebackAlert';

export const getChargebackAlerts = async (page: number): Promise<ChargebackAlertResponse> => {
  const url = `/chargeback_alerts?page=${page}`;

  const response = await paymentsApi.get(
    url
  );

  return response.data;
};

import React from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { Item, Order } from 'models/Order';
import DetailsText from 'components/details/DetailsText';
import { formatDate, formatTime } from 'helper/time';
import { getOrderColor, getOrderStatus } from 'helper/order';
import { formatCurrency } from 'helper/currency';
import TransactionPanel from "./TransactionPanel"
import TrackingCode from 'views/admin/orders/components/TrackingCode';
import { DeleteModal } from 'components/modal/DeleteModal';
import LoadingPage from 'components/loading/LoadingPage';
import { useUserStore } from 'store/reducers/user';
import { cancelOrder } from 'services/AdminApi/orders';
import { notifyError } from 'utils/notification';
import { chargeback } from 'services/PaymentsApi/orders';
import { PaymentMethodKey } from 'models/PaymentMethod';

type OrderDetailsProps = {
  order: Order
  setOrder: Function
}

export default function OrderDetails({ order, setOrder }: OrderDetailsProps) {
  const token = useUserStore((state) => state.auth.token);
  const [openCancelModal, setOpenCancelModal] = React.useState<boolean>(false);
  const [openChargebackModal, setOpenChargebackModal] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(false);

  const orderDate = formatDate(order.processed_at);
  const orderTime = formatTime(order.processed_at);
  const customer = order.customer;
  const transaction = order.transaction;
  const shipping = order.shipping;

  const padding = "20px";
  const selectedTab = { color: '#9966ff', fontWeight: 'bold' };

  const getColumnCount = () => {
    const columnCount = useBreakpointValue({ base: 1, md: 2 });
    return columnCount || 1;
  };

  const getAddress = () => {
    let address = customer.address1;
    if (customer.address2) {
      address += `, ${customer.address2}`
    }
    return address;
  }

  const getFullName = () => {
    let fullName = customer.first_name;
    if (customer.last_name) {
      fullName += ` ${customer.last_name}`
    }
    return fullName;
  }

  const requestCancelOrder = () => {
    setOpenCancelModal(true);
  }

  const requestChargeback = () => {
    setOpenChargebackModal(true);
  }

  const handleCancelOrder = async () => {
    try {
      setLoading(true);
      const response = await cancelOrder(order.order_number, transaction.type.name, token);

      if (response) {
        setOrder({
          ...order,
          transaction: {
            ...order.transaction,
            status: {
              name: "cancelled",
              id: 4
            }
          }
        });
      }

      setOpenCancelModal(false);
    } catch (error: any) {
      if (error?.response?.data?.message) {
        notifyError(error.response.data.message);
      } else {
        notifyError("Ops! Tivemos um problema ao cancelar o pedido.");
      }
    } finally {
      setLoading(false);
    }
  }

  const handleChargeback = async () => {
    try {
      setLoading(true);
      const response = await chargeback(order.id);

      if (response) {
        setOrder({
          ...order,
          transaction: {
            ...order.transaction,
            status: {
              name: "chargeback",
              id: 6
            }
          }
        });
      }

      setOpenChargebackModal(false);
    } catch (error: any) {
      if (error?.response?.data?.message) {
        notifyError(error.response.data.message);
      } else {
        notifyError("Ops! Tivemos um problema ao efetuar o chargeback.");
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Card
      flexDirection="column"
      marginTop={"10px"}
      w="100%"
      px="40px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <LoadingPage isOpen={loading} />
      <Box py={padding}>
        <Text fontSize="32px" fontWeight="bold">
          Pedido #{order.order_number}
        </Text>
        <Text>
          Id no Provedor: {order.order_id}
        </Text>
        <Flex alignItems={{ base: "left", md: "center" }} direction={{ base: "column", md: "row" }}>
          <Box>
            <Text color={getOrderColor(transaction?.status.name)}>
              <b>{transaction ? getOrderStatus(transaction.status.name) : ""}</b>
            </Text>
            <Text>
              {orderDate} - {orderTime}
            </Text>
          </Box>
          {transaction && transaction.status.name === "completed" && (
            <Box ml={{ base: 0, md: "30px" }}>
              <Button colorScheme='red' onClick={requestCancelOrder}>
                Cancelar Pedido
              </Button>
            </Box>
          )}
          {transaction && transaction.status.name === "completed" && transaction.type.name == PaymentMethodKey.CREDIT && (
            <Box ml={{ base: 0, md: "20px" }}>
              <Button colorScheme='orange' onClick={requestChargeback}>
                Efetuar Chargeback
              </Button>
            </Box>
          )}
        </Flex>
      </Box>
      <Tabs variant='enclosed'>
        <TabList>
          <Tab _selected={selectedTab}>Resumo</Tab>
          {transaction && (
            <Tab _selected={selectedTab}>Pagamento</Tab>
          )}
          {customer && (
            <Tab _selected={selectedTab}>Cliente</Tab>
          )}
          {customer && (
            <Tab _selected={selectedTab}>Entrega</Tab>
          )}
          {order.tracking_code && (
            <Tab _selected={selectedTab}>Rastreamento</Tab>
          )}
        </TabList>

        <TabPanels>
          <TabPanel>
            <Box>
              <DetailsText title={"Valor Total"} value={"R$ " + order.total_price} />
              <DetailsText title={"Valor dos Produtos"} value={"R$ " + order.total_line_items_price} />
              {shipping && (
                <DetailsText title={"Valor do Frete"} value={formatCurrency(shipping.price, 2)} />
              )}
              <DetailsText title={"Descontos"} value={"R$ " + order.total_discounts} />
            </Box>
          </TabPanel>
          {transaction && (
            <TabPanel>
              <TransactionPanel transactionData={order.transaction}/>
            </TabPanel>
          )}
          {customer && (
            <TabPanel>
              <Box>
                <DetailsText title={"Nome Completo"} value={getFullName()} />
                <DetailsText title={"E-mail"} value={customer.email} />
                <DetailsText title={"Telefone"} value={customer.phone} />
                {customer.document && (
                  <DetailsText title={"CPF"} value={customer.document} />
                )}
                {customer.birthdate && (
                  <DetailsText title={"Data de Nascimento"} value={formatDate(customer.birthdate)} />
                )}
              </Box>
            </TabPanel>
          )}
          {customer && (
            <TabPanel>
              <Box>
                <DetailsText title={"Nome Completo"} value={getFullName()} />
                <DetailsText title={"Endereço"} value={getAddress()} />
                <DetailsText title={"Cidade"} value={customer.city} />
                <DetailsText title={"Estado"} value={customer.province_code} />
                <DetailsText title={"CEP"} value={customer.zip} />
                {shipping && (
                  <DetailsText title={"Tipo do Frete"} value={shipping.title} />
                )}
              </Box>
            </TabPanel>
          )}
          {order.tracking_code && (
            <TabPanel>
              <TrackingCode order={order} />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
      <Divider />
      <Box>
        <Text fontSize="28px" fontWeight="bold" pt={padding}>
          Produtos
        </Text>
        <Grid templateColumns={`repeat(${getColumnCount()}, 1fr)`} gap={6} py={padding}>
          {order.items.map((item: Item, index: number) => (
            <GridItem key={index}>
              <Box borderWidth="1px" borderRadius="lg" p={4}>
                <Text fontSize="20px" fontWeight="bold" mb={2}>
                  {item.name}
                </Text>
                <DetailsText title={"Quantidade"} value={`${item.quantity}`} />
                <DetailsText title={"Preço"} value={`R$ ${item.price}`} />
              </Box>
            </GridItem>
          ))}
        </Grid>
      </Box>
      <DeleteModal
        title="Cancelar Pedido?"
        isOpen={openCancelModal}
        onClose={() => setOpenCancelModal(false)}
        handleDelete={handleCancelOrder}
        cancelButtonValue="Voltar"
        actionButtonValue="Cancelar Pedido"
        body={[
          "Ao cancelar o pedido, o comprador terá o valor pago integralmente reembolsado, e o montante correspondente ao pedido será deduzido do saldo do seller.",
        ]}
      />
      <DeleteModal
        title="Efetuar Chargeback?"
        isOpen={openChargebackModal}
        onClose={() => setOpenChargebackModal(false)}
        handleDelete={handleChargeback}
        cancelButtonValue="Voltar"
        actionButtonValue="Efetuar Chargeback"
        body={[
          "Ao efetuar o chargeback, o comprador terá o valor pago integralmente reembolsado, e o montante correspondente ao pedido será deduzido do saldo do seller.",
          "Além disso, será deduzida uma taxa de R$ 40,00 do saldo do seller, referente ao custo do alerta do chargeback.",
        ]}
      />
    </Card>
  );
}

import { useState, useEffect } from 'react';
import { SimpleGrid, Select, Box, Table, Thead, Tbody, Tr, Th, Td, Input, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, color } from '@chakra-ui/react';
import { useUserStore } from 'store/reducers/user';
import { FaSearch, FaIdCard, FaFileDownload, FaCheck, FaTimes, FaThumbsUp, FaPaperPlane, FaComment, FaEnvelope, FaFlag, FaConnectdevelop, FaCloudDownloadAlt } from 'react-icons/fa';
import $ from "jquery";
import "jquery-mask-plugin";
import axios from "axios";
import { adminApi } from 'services/AdminApi/api';

export default function UserReports() {
  const [logData, setLogData] = useState([]);
  const [selectedLog, setSelectedLog] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const [selectedURL, setSelectedURL] = useState('');
  const [searchText, setSearchText] = useState('');


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await adminApi.get(`${process.env.REACT_APP_API_URL}/api/log`);
        setLogData(parseLogData(response.data));
        
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
    
  }, []);

  const parseLogData = (logText: any) => {

  let regex = /(\[\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\])/;

  const splitLogs = logText.split(regex).filter((log: string) => log.trim());
  const logEntries: Array<string> = [];
  for(let i=0; i<splitLogs.length; i+=2) {
      logEntries.push(splitLogs[i].trim() + ' ' + splitLogs[i+1].trim().replace(/[\n\r]+|[\s]{2,}/g, ' '));
  }
  
  
  const logs: any = [];

  logEntries.forEach((logEntry: any) => {
    const logObject = {
      origem: '',
      date: '',
      ip: '',
      url: '',
      message: '',
      payload: '',
      erro: ''
    };
    const logParts = logEntry.split('#&');
    
    
    logObject.origem = logParts[1]? logParts[1].replace('ORIGEM:', ''): '';
    logObject.date = logParts[2]? logParts[2].replace('data:', ''): '';
    logObject.ip = logParts[3]? logParts[3].replace('IP:', ''): '';
    logObject.url = logParts[4]? logParts[4].replace('URL:', ''): '';
    logObject.message = logParts[5]? logParts[5].replace('REFERENCIA:', '').split('=')[0]: '';
    logObject.payload = logParts[6]? logParts[6].replace('PAYLOAD:', ''): '';
    logObject.erro = logParts[5]? logParts[5].replace('REFERENCIA:', '').split('=')[1]: '';
    logs.push(logObject);
  });

  const totalFilteredPages = Math.ceil(logs.length / itemsPerPage);
  setTotalPages(totalFilteredPages);
  return logs.reverse();
}



  


    // Função para alterar a página atual
    const changePage = (page: any) => {
      setCurrentPage(page);
    };

      // Função para ir para a próxima página
    const nextPage = () => {
      setCurrentPage((prevPage) => prevPage + 1);
    };

    // Função para ir para a página anterior
    const previousPage = () => {
      setCurrentPage((prevPage) => prevPage - 1);
    };

    

    const handleLogClick = (log: any) => {
      setSelectedLog(log);
    };
  
    const handleCloseModal = () => {
      setSelectedLog(null);
    };

    const handleItemsPerPageChange = (e: any) => {
      setItemsPerPage(parseInt(e.target.value));
      setCurrentPage(1);
    }

    // Função para lidar com a mudança de status
    const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedStatus(e.target.value);
      setCurrentPage(1);
      const filteredData = logData.filter((logEntry) => {
        return e.target.value === '' || logEntry.origem === e.target.value;
      });
      const totalFilteredPages = Math.ceil(filteredData.length / itemsPerPage);
      setTotalPages(totalFilteredPages);
    };

    // Função para filtrar os dados de log
    const filterLogData = (logEntry: any) => {
      const statusMatch = selectedStatus === '' || logEntry.origem === selectedStatus;
      const urlMatch = selectedURL === '' || logEntry.url === selectedURL;
      const searchTextMatch = logEntry.message?.toLowerCase().includes(searchText.toLowerCase())
      || logEntry.payload?.toLowerCase().includes(searchText.toLowerCase())
      || logEntry.erro?.toLowerCase().includes(searchText.toLowerCase());
      
      return statusMatch && urlMatch && searchTextMatch;
    };


    const getFilteredPaginatedData = () => {
      const filteredData = logData.filter(filterLogData);
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      return filteredData.slice(startIndex, endIndex);
    };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Box overflow={"auto"} maxHeight={"85vh"} minHeight={"85vh"}>
      <Box mb={4} display="flex" alignItems="center">
          <Box mr={2}>Linhas por página:</Box>
          <Select value={itemsPerPage} onChange={handleItemsPerPageChange} width="auto" style={{backgroundColor: 'white'}}>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </Select>

          <Box mr={2}>Status:</Box>
          <Select value={selectedStatus} onChange={handleStatusChange} width="auto" style={{ backgroundColor: 'white' }}>
            <option value="">Todos</option>
            <option value="INFO">INFO</option>
            <option value="ERRO">ERRO</option>
            <option value="AVISO">ALERTAS</option>
          </Select>

          <Box mr={2}>URL:</Box>
          <Select value={selectedURL} onChange={(e) => setSelectedURL(e.target.value)} width="auto" style={{ backgroundColor: 'white' }}>
            <option value="">Todas</option>
            {Array.from(new Set(logData.map((logEntry) => logEntry.url))).map((url, index) => (
              <option key={index} value={url}>{url}</option>
            ))}
          </Select>

          <Box mr={2}>Buscar:</Box>
          <Input value={searchText} onChange={(e) => setSearchText(e.target.value)} width="auto" style={{ backgroundColor: 'white' }} />

      </Box>
      <Table variant="simple" borderWidth="1px" borderColor="gray.600" bg="white" fontSize="12px">
        <Thead>
          <Tr bg="gray.200">
            <Th fontSize="small" textAlign="center" px={1} py={1}>Status</Th>
            <Th fontSize="small" textAlign="center" px={1} py={1}>Data</Th>
            <Th fontSize="small" textAlign="center" px={1} py={1}>IP</Th>
            <Th fontSize="small" textAlign="center" px={1} py={1}>Local/URL</Th>
            <Th fontSize="small" textAlign="center" px={1} py={1}>Referência</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
        {getFilteredPaginatedData().map((logEntry, index) => (
          <Tr key={index} _hover={{ bg: 'gray.200', cursor: 'pointer' }} onClick={() => handleLogClick(logEntry)}>
            <Td>{logEntry.origem}</Td>
            <Td>{logEntry.date}</Td>
            <Td>{logEntry.ip}</Td>
            <Td>{logEntry.url}</Td>
            <Td>{logEntry.message}</Td>
            <Td></Td>
          </Tr>
        ))}
        </Tbody>
      </Table>
      <div>
        <button onClick={previousPage} disabled={currentPage === 1} style={{padding:'3px'}}>
          Anterior 
        </button>

        {/* Renderizar os botões de página */}
        {Array.from({ length: totalPages }).map((_, index) => {
          // Verificar se o índice está dentro do intervalo desejado
          if (
            index >= currentPage - 5 &&
            index <= currentPage + 5 &&
            index >= 0 &&
            index < totalPages
          ) {
            return (
              <button
                key={index + 1}
                onClick={() => changePage(index + 1)}
                disabled={currentPage === index + 1}
                style={currentPage === index + 1?{ padding: '3px', fontWeight: 'bold', textDecoration: 'underline' }:{ padding: '3px' }}
              >
                {index + 1 < 10 ? `0${index + 1}` : index + 1}
              </button>
            );
          }
          return null;
        })}

        {/* Renderizar o botão "Próxima" */}
        <button onClick={nextPage} disabled={currentPage === totalPages} style={{padding:'3px'}}>
          Próximo
        </button>
      </div>



        {/* Modal para exibir o payload e o erro */}
      {selectedLog && (
        <Modal isOpen={true} onClose={handleCloseModal} size='6xl'>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Payload e Erro</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box>
                <strong>Payload:</strong> {selectedLog.payload}
              </Box>
              <Box mt={2}>
                <strong>Resposta:</strong> {selectedLog.erro}
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
      </Box>
    </Box>
  );
}



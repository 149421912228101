import React, { useEffect, useState, Fragment } from "react";
import { Text } from "@chakra-ui/react";

export default function TextFilterValue(props: {
  text: string;
  contextValue: any;
  value: any;
  cb: (value: any) => void;
}) {
  if (props.contextValue === props.value) {
    return (
      <React.Fragment>
        <Text
          cursor={"pointer"}
          onClick={() => {
            props.cb("-");
          }}
        >
          <b>{props.text}</b> x
        </Text>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Text
          color="grey"
          cursor={"pointer"}
          onClick={() => {
            props.cb(props.value);
          }}
        >
          {props.text}
        </Text>
      </React.Fragment>
    );
  }
}

import React from "react"
import { Button, Center, Flex, Text } from '@chakra-ui/react';

type PaginationButtonsProps = {
  onClick: Function
  currentPage: number
  lastPage: number
}

export default function PaginationButtons ({ onClick, currentPage, lastPage }: PaginationButtonsProps) {
  return (
    <Flex w="100%" direction="row" justifyContent="center">
      <Center>
        <Button
          size="xs"
          marginRight="10px"
          colorScheme="purple"
          onMouseDown={() => {
            onClick(currentPage - 1);
          }}
          disabled={currentPage === 1}
        >
          {"< Anterior"}
        </Button>
        <Flex flexDirection="row">
          <Text as="samp" fontSize="14px">
            Página{" "}
            <Text as="b" fontSize="14px">
              {currentPage} de {lastPage}
            </Text>
          </Text>
        </Flex>
        <Button
          size="xs"
          marginLeft="10px"
          colorScheme="purple"
          onMouseDown={() => {
            onClick(currentPage + 1);
          }}
          disabled={
            currentPage === lastPage
          }
        >
          {"Próxima >"}
        </Button>
      </Center>
    </Flex>
  );
}

import React from "react";
import {
  Box,
  BoxProps,
  Button,
  Flex,
  FlexProps,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

type SellerBalanceFilterFields = {
  name: string
  email: string
}

type OrderFiltersProps = {
  showFilters: boolean,
  setFilters: Function,
}

export default function SellerBalanceFilters({ showFilters, setFilters }: OrderFiltersProps) {
  const flexStyle: FlexProps = {
    direction: {base: "column", md: "row"},
    justify: "space-between"
  }

  const boxStyle: BoxProps = {
    w: {base: "100%", md: "48%"},
    py: "10px"
  }

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<SellerBalanceFilterFields>();

  const handleFilter = (data: SellerBalanceFilterFields) => {
    setFilters(data);
  }

  const handleReset = () => {
    reset();
    setFilters({});
  }

  if (showFilters) {
    return (
      <Box px="25px" pb="20px">
        <form onSubmit={handleSubmit(handleFilter)}>
          <Flex {...flexStyle}>
            <Box {...boxStyle}>
              <FormLabel htmlFor="name">Nome</FormLabel>
              <Input
                id="name"
                placeholder="Digite um nome"
                {...register("name")}
              />
            </Box>
            <Box {...boxStyle}>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                id="email"
                placeholder="Digite um email"
                {...register("email")}
              />
            </Box>
          </Flex>

          <Button type='submit' colorScheme="purple" margin="5px">Filtrar</Button>
          <Button onClick={handleReset} margin="5px">Limpar</Button>
        </form>
      </Box>
    );
  }

  return (<></>);
}

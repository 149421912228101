import React, { ReactNode } from 'react';
import {H1Style, H2Style, H3Style, H4Style, H5Style, H6Style, H7Style, H8Style,H9Style, SubtitleStyle} from "./styles";

interface StyleMap {
    [key: string]: React.ComponentType<any>;
}

const styleMap: StyleMap = {
    'h1': H1Style,
    'h2': H2Style,
    'h3': H3Style,
    'h4': H4Style,
    'h5': H5Style,
    'h6': H6Style,
    'h7': H7Style,
    'h8': H7Style,
    'h9': H7Style,
    'subtitle': SubtitleStyle,
  };

  
interface TextProps {
    type: string;
    children: ReactNode;
}

export const Text = ({ type, children }: TextProps) => {
    const Tag = styleMap[type] || H1Style; // Fallback para H1Style se o tipo não for válido

    return (
        <Tag>
        {children}
        </Tag>
    );
};
import React from 'react'
import { Box, Text } from '@chakra-ui/react';

type DetailsTextProps = {
  title: string
  value: string | boolean | JSX.Element
}

export default function DetailsText({ title, value }: DetailsTextProps) {
  let displayValue = value;

  if (value === true) {
    displayValue = "Sim";
  } else if (value === false) {
    displayValue = "Não";
  }

  return (
    <Box mb={2}>
      <Text fontSize="18px">
        <strong>{title}</strong>
      </Text>
      <Text  fontSize="16px">
        {displayValue}
      </Text>
    </Box>
  )
}

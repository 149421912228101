import { ProductResponse } from "models/Product";
import { create } from "zustand";
import { persist } from "zustand/middleware";

interface IProduct {
  productsData: ProductResponse;
  updateProductsData: (productsData: ProductResponse) => void;
  clearProductsData: () => void;
}

export const useProductStore = create<IProduct>()(
  persist(
    (set) => ({
      productsData: {
        data: [],
      },
      updateProductsData: (productsData) =>
        set((state) => ({
          productsData: productsData,
        })),
      clearProductsData: () => {
        useProductStore.persist.clearStorage();
      },
    }),
    {
      name: "products-store",
    }
  )
);

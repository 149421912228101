import React from 'react';
import { Box, Button } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getAllOrders } from 'services/AdminApi/orders';
import { Order } from 'models/Order';
import LoadingPage from 'components/loading/LoadingPage';
import OrderDetails from './components/OrderDetails';
import CheckTable from './components/CheckTablePedidos';

export default function OrdersMenu() {
  const [filters, setFilters] = React.useState({});
  const [page, setPage] = React.useState(1);
  const [details, setDetails] = React.useState<Order>(null);

  const { data, isLoading, refetch } = useQuery(
    ['orders', { page, filters }],
    () => getAllOrders(page, filters),
    {
      onError: (error) => {
        console.log(error)
      }
    }
  );

  return (
    <Box pt={{ base: "130px", md: "80px" }}>
      {details ? (
        <>
          <Button onClick={() => setDetails(null)}>
            Voltar
          </Button>
          <OrderDetails order={details} setOrder={(order: Order) => {
            setDetails(order);
            refetch();
          }} />
        </>
      ) : (
        <CheckTable
          tableData={data}
          tableName="Pedidos"
          setFilters={setFilters}
          showDetails={setDetails}
          page={page}
          setPage={setPage}
        />
      )}

      <LoadingPage isOpen={isLoading || data === undefined} />
    </Box>
  );
}





export const transformDateToString  = (date: Date) => {
    
            
                const day = date.getDate(); // Obtenha o dia do mês (1-31)
                const month = date.getMonth() + 1; // Obtenha o mês (0-11) e adicione 1
                const year = date.getFullYear(); // Obtenha o ano com quatro dígitos


                return `${String(day).padStart(2, "0")}/${String(month).padStart(2, "0")}/${year}`
}
import React, {useState} from 'react';
import {InputStyles} from "./styles";

interface CustomSelectProps {
  children: React.ReactNode;
  [key: string]: any; // Outras props opcionais
}

export const CustomSelect = ({ children, ...rest }: CustomSelectProps) => {

    const [inputType, setInputType] = useState(rest.type);

    const toggleInputType = () => {
        setInputType(inputType === 'password' ? 'text' : 'password');
      };

  return <InputStyles {...rest}>{children} </InputStyles>;
};


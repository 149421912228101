import React from 'react';
import {
	Box,
	Button,
	FormControl,
	FormLabel,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	Spinner,
	Flex
} from '@chakra-ui/react';
import { formatCurrency } from 'helper/currency';
import { useForm } from 'react-hook-form';
import { DubpayWithdrawRequest } from 'models/ProviderBalance';
import { notifyError, notifySuccess } from 'utils/notification';
import { AnticipationSelectedWaitingFunds, SellersBalance } from 'models/SellerBalance';
import MoneyInput from 'components/moneyInput/MoneyInput';
import {
	anticipateSellerBalance,
	getSellerAvailableWaitingFunds,
} from 'services/PaymentsApi/accountsBalance';
import { useQuery } from '@tanstack/react-query';

type AnticipationModalProps = {
	isOpen: boolean;
	onClose: () => void;
	seller: SellersBalance;
	setSeller: Function;
};

export default function AnticipationModal({ isOpen, onClose, seller, setSeller }: AnticipationModalProps) {
	const sellerName = `${seller.first_name} ${seller.last_name}`;
	const [confirmAnticipationTab, setConfirmAnticipationTab] = React.useState(false);
	const [selectedAnticipations, setSelectedAnticipations] = React.useState<AnticipationSelectedWaitingFunds>(null);
	const [isAnticipating, setIsAnticipating] = React.useState(false);

	const { data: availableWaitingFunds, isFetching: isLoadingData } = useQuery(['sellerAvailableWaitingFunds', seller], () => getSellerAvailableWaitingFunds(seller.id), {
		enabled: isOpen,
		refetchOnWindowFocus: false,
	});

	const closeConfirmAnticipationTab = () => {
		setSelectedAnticipations(null);
		setConfirmAnticipationTab(false);
	}

	const handleClose = () => {
		closeConfirmAnticipationTab();
		onClose();
	}

	const confirmAnticipation = async (data: any) => {
		const amount = parseInt(data.amount.replace(',', '').replace('.', ''));

		if (amount > getAvailableAnticipationAmount()) {
			notifyError('Valor solicitado maior que o disponível para antecipação!');
			return;
		}

		const selectedAnticipations = getSelectedAnticipations(amount);

		if (selectedAnticipations.amount === 0) {
			notifyError('Não é possível antecipar esse valor. Aumente o valor desejado');
			return;
		}

		setSelectedAnticipations(selectedAnticipations);
		setConfirmAnticipationTab(true);
	}

	const handleAnticipation = async (selectedAnticipations: AnticipationSelectedWaitingFunds) => {
		try {
			setIsAnticipating(true);

			await anticipateSellerBalance(seller.id, selectedAnticipations);

			setSeller({
				...seller,
				balance: {
					...seller.balance,
					waiting_funds: seller.balance.waiting_funds - selectedAnticipations.amount,
					balance: seller.balance.balance + selectedAnticipations.amount,
				}
			});

			notifySuccess(`Antecipação de ${formatCurrency(selectedAnticipations.amount, 2)} realizada com sucesso!`);

			handleClose();
		} catch (e: any) {
			if (e?.response?.data?.message && typeof e.response.data.message === 'string') {
				notifyError('Erro: ' + e.response.data.message);
			} else {
				notifyError('Erro ao antecipar!');
			}
		} finally {
			setIsAnticipating(false);
		}
	}

	const getAvailableAnticipationAmount = () => {
		return availableWaitingFunds?.reduce(
			(accumulator, currentValue) => accumulator + currentValue.value,
			0
		) || 0;
	}

	const getSelectedAnticipations = (desiredAmount: number): AnticipationSelectedWaitingFunds => {
		if (!availableWaitingFunds) return {
			selectedWaitingFundsIds: [],
			amount: 0,
		};

		let currentAmount = 0;
		const selectedWaitingFundsIds: number[] = [];

		for (let i = 0; i < availableWaitingFunds.length; i++) {
			if (currentAmount + availableWaitingFunds[i].value > desiredAmount) break;
			currentAmount += availableWaitingFunds[i].value;
			selectedWaitingFundsIds.push(availableWaitingFunds[i].id);
		}

		return {
			selectedWaitingFundsIds,
			amount: currentAmount
		};
	}

	const {
		watch,
		handleSubmit,
		setValue,
	} = useForm<DubpayWithdrawRequest>();
	const amountValue = watch('amount');

	return (
		<Modal isOpen={isOpen} onClose={handleClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Antecipar Lançamentos</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					{isLoadingData ? (
						<Flex width='100%' justifyContent="center" mb="20px">
							<Spinner color="green.500" />
						</Flex>
					) : (
						<>
							<Text><b>Seller:</b> {sellerName}</Text>
							<Text mb="10px"><b>Disponível para Antecipação:</b> {
								formatCurrency(getAvailableAnticipationAmount(), 2)}</Text>
							{confirmAnticipationTab ? (
								<Box>
									<Box>
										<Text>Valor que Será Antecipado</Text>
										<Text fontSize="22px" fontWeight="bold">{formatCurrency(selectedAnticipations.amount, 2)}</Text>
									</Box>
									<Box>
										<Text>Lançamentos Futuros Após Antecipação</Text>
										<Text fontSize="22px" fontWeight="bold">{formatCurrency(seller.balance.waiting_funds - selectedAnticipations.amount, 2)}</Text>
									</Box>
									<Box>
										<Text>Saldo Após Antecipação</Text>
										<Text fontSize="22px" fontWeight="bold">{formatCurrency(seller.balance.balance + selectedAnticipations.amount, 2)}</Text>
									</Box>
									<Box display="flex" justifyContent="right" my="10px">
										<Button type='submit' onClick={closeConfirmAnticipationTab} variant="outline"  mr="10px">
											Voltar
										</Button>
										<Button type='submit' onClick={() => handleAnticipation(selectedAnticipations)} colorScheme="green" disabled={isAnticipating}>
											{isAnticipating ? 'Antecipando...' : 'Confirmar'}
										</Button>
									</Box>
								</Box>
							) : (
								<form onSubmit={handleSubmit(confirmAnticipation)}>
									<FormControl>
										<FormLabel htmlFor="amount">Valor Desejado</FormLabel>
										<MoneyInput id="amount" value={amountValue} setValue={setValue} />
										<Text fontSize="12px" my="5px" fontStyle="italic">Obs: Como a antecipação é feita pela soma dos valores dos pedidos, nem sempre o valor desejado será o valor que de fato poderá ser antecipado.</Text>
										<Box display="flex" justifyContent="right">
											<Button type='submit' colorScheme="green" my="10px">
												Obter Antecipação
											</Button>
										</Box>
									</FormControl>
								</form>
							)}
						</>
					)}
				</ModalBody>
			</ModalContent>
		</Modal>
	);
}

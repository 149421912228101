import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

type Page = {
  name: string;
  data: any[];
}

export const exportToExcel = (json: any, name: string) => {
  const worksheet = XLSX.utils.json_to_sheet(json);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, name);

  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

  saveAs(blob, name + ".xlsx");
}

export const exportToExcelMultiPage = (pages: Page[], name: string) => {
  const worksheet = pages.map(page => XLSX.utils.json_to_sheet(page.data, { header: Object.keys(page.data[0]) }));
  const workbook = XLSX.utils.book_new();
  worksheet.forEach((ws, i) => XLSX.utils.book_append_sheet(workbook, ws, pages[i].name));

  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

  saveAs(blob, name + ".xlsx");
}

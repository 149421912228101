import { createContext, ReactNode, useState, useCallback, useContext } from 'react'

interface ProviderProps {
    children: ReactNode;
}

type Params = {
    page?: number;
    limit?: number;
    situation?: string;
    invoicing?: string;
    createdAt?: string;
    name?: string;
    show?: boolean;
    totalPages?: number;
    status?: boolean;
}
interface ContextProps {
    page: number;
    limit: number;
    situation: string;
    invoicing: string;
    createdAt: string;
    name: string;
    setParams: (params: Params) => void;
    show?: boolean;
    totalPages?: number;
    status?: boolean;
    openUpdateModal: boolean;
    openDeleteModal: boolean;
    setOpenUpdateModal: (value: boolean) => void;
    setOpenDeleteModal: (value: boolean) => void;
    idPlan: number;
    setIdPlan: (value: number) => void;
}

export const ContextUserData = createContext<ContextProps | null>(null)

export function ContextProvider({ children }: ProviderProps) {

    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10);
    const [situation, setSituation] = useState("");
    const [invoicing, setInvoicing] = useState("");
    const [createdAt, setCreatedAt] = useState("");
    const [name, setName] = useState("");
    const [show, setShow] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [status, setStatus] = useState<boolean>(undefined)
    const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false)
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
    const [idPlan, setIdPlan] = useState<number>(null)

    const setParams = (params: Params) => {
        console.log({params})
        if (params.page) setPage(params.page)
        if (params.limit) setLimit(params.limit)
        if (params.situation) setSituation(params.situation)
        if (params.invoicing) setInvoicing(params.invoicing)
        if (params.createdAt) setCreatedAt(params.createdAt)
        if (params.name || params.name === "") setName(params.name)
        if (params.totalPages) setTotalPages(params.totalPages)
        if (typeof params.status === 'boolean') {
            setStatus(params.status)
        } else {
            setStatus(undefined)
        }

        if (params.show === true || params.show == false) {
            setShow(params.show)
        }

    }

    const contextValue: ContextProps = {
        page,
        limit,
        situation,
        invoicing,
        createdAt,
        name,
        setParams,
        show,
        totalPages,
        status,
        openUpdateModal,
        openDeleteModal,
        setOpenUpdateModal,
        setOpenDeleteModal,
        idPlan,
        setIdPlan
    };

    console.log({contextValue})
    return (
        <ContextUserData.Provider value= { contextValue }>
        { children }
        </ContextUserData.Provider>
      )
}

export function useContextLocal() {
    const context = useContext(ContextUserData)

    return context
}






import React from 'react'
import { Button } from '@chakra-ui/react';

type ShowFiltersProps = {
  showFilters: boolean
  setShowFilters: Function
}

export default function ShowFilters({ showFilters, setShowFilters }: ShowFiltersProps) {
  return (
      <Button colorScheme='purple' onClick={() => setShowFilters(!showFilters)}>
        {!showFilters ? "Filtros" : "Fechar"}
      </Button>
  )
}

import React, { useEffect, useState } from "react";
import {
  Button,
  ModalContent,
  Modal,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text
} from "@chakra-ui/react";
import { useContextUser } from "../../../../hooks/contextApi";
import { useContextPlan } from "../../../../hooks/membershipContext";
import { useContextLocal } from "../variables/localContext";
import { RowObj } from "../models/rowMembershipObj";
import { updateMembershipPlans } from "services/AdminApi/membershipPlan";


export default function Users() {
  // Chakra Color Mode
  const { show, setParams } = useContextLocal();
  const { getUserData } = useContextUser();
  const { createMembershipPlan, getMembershipPlan, updateMembershipPlan } = useContextPlan();
  const [name, setName] = useState<string>("");
  const [totalPrice, setTotalPrice] = useState<string>("");

  const {
    setOpenDeleteModal,
    openDeleteModal,
    idPlan
  } = useContextLocal()
  const desactivatePlan = async () => {
   
    await updateMembershipPlan({id: idPlan, status: false});
    await getMembershipPlan();
    alert(`Plano id: ${idPlan} desativado `)
    onClose();
  };

  const onClose = () => {
    setOpenDeleteModal(false);
  }

  return (
    <React.Fragment>
        <Modal isCentered isOpen={openDeleteModal} onClose={()=> {onClose()}}>
        Teste
        <ModalContent>
          <ModalHeader>Tem certeza disso?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>O plano será desativado. Para reativá-lo entre em contato com os administradores do sistema</Text>
          </ModalBody>
          <ModalFooter>
          <Button bg={'green'} color={'white'} onClick={()=> {desactivatePlan()}}>Sim</Button>
          <Button bg={'red'} color={'white'} onClick={()=> {onClose()}}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </React.Fragment>
  );
}

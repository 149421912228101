import { paymentsApi } from "./api";

export const updateDescriptor = async (userId: string, descriptor: string): Promise<void> => {
  const url = `/seller/update_descriptor`;

  await paymentsApi.post(
    url,
    {
      user_id: userId,
      descriptor
    }
  );
};

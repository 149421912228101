import React from 'react';
import { Box, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getSellerWaitingFunds } from 'services/PaymentsApi/accountsBalance';
import { formatCurrency } from 'helper/currency';
import PaginationButtons from 'components/paginationButtons/PaginationButtons';
import { formatDate, formatTime } from 'helper/time';
import LoadingPage from 'components/loading/LoadingPage';
import { SellersBalance } from 'models/SellerBalance';

type SellerWaitingFundsProps = {
	seller: SellersBalance;
}

export default function SellerWaitingFunds({ seller }: SellerWaitingFundsProps) {
	const [page, setPage] = React.useState(1);

	const { data: waitingFunds, isLoading: isLoadingData } = useQuery(['sellerWaitingFunds', seller, page], () => getSellerWaitingFunds(seller.id, page), {
		refetchOnWindowFocus: false,
	});

	if (!isLoadingData) {
		return (
			<Box backgroundColor="white" px="10px" py="20px" borderRadius="20px" mt="20px">
				{waitingFunds && waitingFunds.data.length > 0 ? (
					<>
						<Box display="flex" justifyContent="space-between" alignContent="center" mb="20px" px="10px">
							<Text
								color="secondaryGray.900"
								fontSize="20px"
								fontWeight="700"
								lineHeight="100%"
							>
								Lançamentos Futuros
							</Text>
						</Box>
						<TableContainer>
							<Table variant='simple'>
								<Thead>
									<Tr>
										<Th>Data</Th>
										<Th>Disponível para Antecipação</Th>
										<Th>Descrição</Th>
										<Th>Valor</Th>
									</Tr>
								</Thead>
								<Tbody>
									{waitingFunds.data.map(waitingFund => (
										<Tr key={waitingFund.id} backgroundColor={
											new Date(waitingFund.available_date) <= new Date() ? "rgba(0, 255, 0, 0.2)" : "white"
										}>
											<Td>{formatDate(waitingFund.date)} - {formatTime(waitingFund.date)}</Td>
											<Td>{formatDate(waitingFund.available_date)}</Td>
											<Td>{waitingFund.description}</Td>
											<Td fontWeight="bold">{formatCurrency(waitingFund.value, 2)}</Td>
										</Tr>
									))}
								</Tbody>
							</Table>
							<Box display="flex" alignItems="center" mt="20px">
								<PaginationButtons onClick={setPage} currentPage={page} lastPage={waitingFunds?.meta?.last_page} />
							</Box>
						</TableContainer>
					</>
				) : (
					<>
						<Text
							color="secondaryGray.900"
							fontSize="20px"
							fontWeight="700"
							lineHeight="100%"
						>
							Nenhum lançamento futuro encontrado
						</Text>
					</>
					)}
			</Box>
		);
	} else {
		return (
			<LoadingPage isOpen={isLoadingData} />
		)
	}
}

import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  
  Divider,
  Input,
  SimpleGrid,
  Heading,
  
} from "@chakra-ui/react";
import { useContextUser } from "../../../../hooks/contextApi";
import { useContextLocal } from "../variables/localContext";
import { RowObj } from "../models/rowUserObj";
import Card from "components/card/Card";
import TextFilterValue from "./TextFilterValue";

export default function Users() {
  // Chakra Color Mode

  const { getUserData } = useContextUser();
  const {
    setParams,
    createdAt,
    invoicing,
    name,
    situation,
    show,
    status,
    limit,
    page,
  } = useContextLocal();

  const formatStatus = (value: "0" | "1" | "-" ) => {
   setParams({status: value})
  };

  const formatCreatedAt = (value: string) => {
    setParams({ createdAt: value });
  };
  const formatInvoicing: any = {
    "comecando": "Começando agora",
    "10mil": "Até R$ 10.000",
    "100mil": "De R$ 10.000 à R$ 100.000",
    "500mil": "De R$ 100.000 à R$ 500.000",
    "1mi": "De R$ 500.000 à R$ 1.000.000",
    "mais1mi": "Acima de R$ 1.000.000",
  };

  const handleInvoicing = (value: string | undefined) => {
   console.log({valueeeee: value});
   setParams({invoicing: value})
  }

const handleSituation = (value: string) => {
  setParams({situation: value});
}

 const runFilter = () => {
  setParams({page: 1})
  setParams({totalPages: 0})
    getUserData({
      page,
      limit,
      status: status === "1" ? true : (status === "0"? false: undefined),
      situation_id: situation === '-' ? undefined: situation,
      name,
      invoicing: invoicing === '-' ? undefined : invoicing,
      createdAt: createdAt === '-' ? undefined: createdAt,
    });

   
    setParams({ show: !show });
  };

  return (
    <React.Fragment>
      <SimpleGrid columns={2} paddingBottom={10} paddingTop={5} spacing={10}>
        <Box>
          <Input
            id="name"
            type="text"
            placeholder="Busque por um nome"
            value={name}
            onChange={(event) => {
              setParams({ name: event.target.value });
            }}
          />
        </Box>
      </SimpleGrid>
      <SimpleGrid columns={4} spacing={10}>
        <Box>
          <Heading fontSize="md">Status</Heading>
          <Divider
            paddingBottom={1}
            paddingTop={1}
            color="black"
            orientation="horizontal"
          />
          <TextFilterValue
           key={0}
            text="Incompleto"
            value={"0"}
            contextValue={status}
            cb={formatStatus}
          />
          <TextFilterValue
           key={1}
            text="Completo"
            value={"1"}
            contextValue={status}
            cb={formatStatus}
          />
        </Box>
        <Box>
          <Heading fontSize="md">Situação</Heading>
          <Divider paddingBottom={1} paddingTop={1} orientation="horizontal" />
          <TextFilterValue
           key={2}
            text="Ativo"
            value={1}
            contextValue={situation}
            cb={handleSituation}
          />
          <TextFilterValue
           key={3}
            text="Ativo e operante"
            value={2}
            contextValue={situation}
            cb={handleSituation}
          />
          <TextFilterValue
           key={4}
            text="Ativo e inoperante"
            value={3}
            contextValue={situation}
            cb={handleSituation}
          />
          <TextFilterValue
           key={5}
            text="Inativo"
            value={4}
            contextValue={situation}
            cb={handleSituation}
          />
        </Box>
        <Box>
          <Heading fontSize="md">Data do registro</Heading>
          <Divider paddingBottom={1} paddingTop={1} orientation="horizontal" />
          <TextFilterValue
          key={6}
            text="Hoje"
            value={"today"}
            contextValue={createdAt}
            cb={formatCreatedAt}
          />
          <TextFilterValue
           key={7}
            text="Esta semana"
            value={"week"}
            contextValue={createdAt}
            cb={formatCreatedAt}
          />
          <TextFilterValue
           key={8}
            text="Este mês"
            value={"month"}
            contextValue={createdAt}
            cb={formatCreatedAt}
          />
          <TextFilterValue
           key={9}
            text="Este ano"
            value={"year"}
            contextValue={createdAt}
            cb={formatCreatedAt}
          />
        </Box>
        <Box> 
        {/* comecando: "Começando agora",
    "10mil": "Até R$ 10.000",
    "100mil": "De R$ 10.000 à R$ 100.000",
    "500mil": "De R$ 100.000 à R$ 500.000",
    "1mi": "De R$ 500.000 à R$ 1.000.000",
    mais1mi: "Acima de R$ 1.000.000", */}
          <Heading fontSize="md">Faturamento</Heading>
          <Divider paddingBottom={1} paddingTop={1} orientation="horizontal" />
          <TextFilterValue
           key={10}
            text="Começando agora"
            value={"Começando agora"}
            contextValue={invoicing}
            cb={handleInvoicing}
          />
          <TextFilterValue
           key={11}
            text="Até R$ 10.000"
            value={"Até R$ 10.000"}
            contextValue={invoicing}
            cb={handleInvoicing}
          />
          <TextFilterValue
           key={12}
            text="De R$ 10.000 à R$ 100.000"
            value={"De R$ 10.000 à R$ 100.000"}
            contextValue={invoicing}
            cb={handleInvoicing}
          />
          <TextFilterValue
           key={13}
            text="De R$ 100.000 à R$ 500.000"
            value={"De R$ 100.000 à R$ 500.000"}
            contextValue={invoicing}
            cb={handleInvoicing}
          />
           <TextFilterValue
            key={14}
            text="De R$ 500.000 à R$ 1.000.000"
            value={"De R$ 500.000 à R$ 1.000.000"}
            contextValue={invoicing}
            cb={handleInvoicing}
          />
          <TextFilterValue
           key={15}
            text="Acima de R$ 1.000.000"
            value={"Acima de R$ 1.000.000"}
            contextValue={invoicing}
            cb={handleInvoicing}
          />
          {/* <Button>Começando agora</Button>
          <Button>Até R$ 10.000</Button>
          <Button>De R$ 10.000 à R$ 100.000</Button>
          <Button>De R$ 100.000 à R$ 500.000</Button>
          <Button>De R$ 500.000 à R$ 1.000.000</Button>
          <Button>Acima de R$ 1.000.000</Button> */}
        </Box>
        <Button
          right={0}
          bg={"#008000"}
          color="white"
          onClick={() => {
            runFilter();
          }}
        >
          Procurar
        </Button>
      </SimpleGrid>

      {/* <Card
        flexDirection="column"
        marginTop={"10px"}
        w="100%"
        px="20px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Box pt={{ base: "130px", md: "60px", xl: "40px" }}>
          <FormControl rowGap="20px">
            
            <FormLabel htmlFor="name">Nome</FormLabel>
            <Input
              id="name"
              type="text"
              value={name}
              onChange={(event) => {
                setParams({ name: event.target.value });
              }}
            />

            <FormLabel htmlFor="email">Status</FormLabel>
            <Select
              id="tipo"
              value={returnStatusValue(status)}
              onChange={(event) => {
                formatStatus(event.target.value);
              }}
            >
              <option value={undefined}>Selecione</option>
              <option value="0">Incompleto</option>
              <option value="1">Completo</option>
            </Select>

            <FormLabel htmlFor="login">Situação</FormLabel>
            <Select
              id="tipo"
              value={situation}
              onChange={(event) => {
                setParams({ situation: event.target.value });
              }}
            >
              <option value={undefined}>Selecione</option>
              <option value="admin">Ativo</option>
              <option value="operacional">Ativo e operante</option>
              <option value="operacional">Ativo e inoperante</option>
              <option value="operacional">Inativo</option>
            </Select>

            <FormLabel htmlFor="tipo">Criado</FormLabel>
            <Select
              id="tipo"
              onChange={(event) => {
                setParams({ createdAt: event.target.value });
              }}
            >
              <option value={undefined}>Selecione</option>
              <option value="today">Hoje</option>
              <option value="week">Esta semana</option>
              <option value="month">Este mês</option>
              <option value="year">Este ano</option>
            </Select>

            <FormLabel htmlFor="status">Faturamento</FormLabel>
            <Select
              id="status"
              value={invoicing}
              onChange={(event) => {
                setParams({ invoicing: event.target.value });
              }}
            >
              <option value={undefined}>Selecione</option>
              <option value="comecando">Começando agora</option>
              <option value="10mil">Até R$ 10.000</option>
              <option value="100mil">De R$ 10.000 à R$ 100.000</option>
              <option value="500mil">De R$ 100.000 à R$ 500.000</option>
              <option value="1mi">De R$ 500.000 à R$ 1.000.000</option>
              <option value="mais1mi">Acima de R$ 1.000.000</option>
            </Select>

            <Button
              onClick={() => {
                runFilter();
              }}
            >
              Procurar
            </Button>
            <Button onClick={() => setParams({ show: !show })}>Voltar</Button>
          
          </FormControl>
        </Box>
      </Card> */}
    </React.Fragment>
  );
}

import styled from "styled-components"

export const Container = styled.div`
    margin: 20px;
`

export const Row = styled.div`
    width: 100%;
`

export const Item = styled.div`
    display: flex; /* Torna o conteúdo flexível */
  align-items: center; /* Centraliza verticalmente o conteúdo */
    justify-content: center;
   
`

export const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    
`
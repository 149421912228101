import { Store } from 'models/Order';
import { adminApi } from './api';

export const getAllStores = async (): Promise<Store[]> => {
  const url = `/api/admin/stores`;
  try {
    const orders = await adminApi.get<Store[]>(
      `${process.env.REACT_APP_API_URL}${url}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      }
    );

    return orders.data;
  } catch (err) {
    console.log(err);
  }
}

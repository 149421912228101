import React from 'react';
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader } from '@chakra-ui/react';

type DeleteModal = {
  title: string
  isOpen: boolean
  onClose: Function
  handleDelete: Function
  actionButtonValue?: string
  cancelButtonValue?: string
  body?: string[]
}

export function DeleteModal({ title, isOpen, onClose, handleDelete, actionButtonValue = "Remover", cancelButtonValue = "Cancelar", body = ["Você não poderá desfazer essa ação."] }: DeleteModal) {
  return (
    <Modal isOpen={isOpen} onClose={() => onClose()}>
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        {body.map(text => (
          <ModalBody>{text}</ModalBody>
        ))}
        <ModalFooter>
          <Button variant="primary" onClick={() => onClose()}>
            {cancelButtonValue}
          </Button>
          <Button variant="primary" onClick={() => handleDelete()} color="crimson">
            {actionButtonValue}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

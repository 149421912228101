
import {
    Flex,
    Box,
    Table,
    Checkbox,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Button
} from '@chakra-ui/react';
import { useContextLocal } from '../variables/localContext'
import { useContextUser } from '../../../../hooks/contextApi'

// import {
//     createColumnHelper,
//     flexRender,
//     getCoreRowModel,
//     getSortedRowModel,
//     SortingState,
//     useReactTable
// } from '@tanstack/react-table';

// // Custom components
// import Card from 'components/card/Card';
// import Menu from 'components/menu/MainMenu';

export default function CheckTable(props: { tableData: any, tableName: any, table: any }) {
    const {
        page,
        limit,
        invoicing,
        name,
        situation,
        createdAt,
        totalPages,
        status,
        setParams } = useContextLocal();
    const {
        getUserData,
        totalResult
    } = useContextUser();
    const { table } = props;


    const onChangePage = (value: number) => {
        setParams({page: value})

        getUserData({
            invoicing: invoicing === '-' ? undefined : invoicing,
            createdAt: createdAt === '-' ? undefined: createdAt,
            name,
            status: status === "1" ? true : (status === "0"? false: undefined),
            situation_id: situation === '-' ? undefined: situation,
            page: value,
            limit
        })
    }


    const onChangeLimit = (value: number) => {
        console.log({value})
        setParams({limit: value});
        setParams({page: 1});
        getUserData({
            invoicing,
            name,
            situation_id: (situation).toString(),
            page: 1,
            limit: value
        });
    }

    const canNextPage = () => {
        console.log({totalPages})
        return page + 1 > totalPages
    }

    const canPreviousPage = () => {
        return page - 1 < 1
    }

    return (
        <div className="flex items-center gap-2">
            <Button
                className="border rounded p-1"
                onClick={() => {
                    onChangePage(1);
                   
                }
            }
                disabled={canPreviousPage()}
            >
                {'<<'}
            </Button>
            <Button
                className="border rounded p-1"
                onClick={() => {
                    table.previousPage();
                    onChangePage(page-1)
                }}
                disabled={canPreviousPage()}
            >
                {'<'}
            </Button>
            <Button
                className="border rounded p-1"
                onClick={(event) => {
                    onChangePage(page + 1);
                   
                }}
                disabled={canNextPage()}
            >
                {'>'}
            </Button>
            <Button
                className="border rounded p-1"
                onClick={() => {
                    onChangePage(page)
                }}
                disabled={canNextPage()}
            >
                {'>>'}
            </Button>
            <span className="flex items-center gap-1">
                <div>Página</div>
                <strong>
                    {page} of{' '}
                    {totalPages}
                </strong>
            </span>
            <select
                value={limit}
                onChange={e => {
                    console.log(Number(e.target.value))
                    onChangeLimit(Number(e.target.value))
                    table.setPageSize(Number(e.target.value))
                }}
            >
                {[5, 10, 15, 25, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                        Exibir {pageSize}
                    </option>
                ))}
            </select>
          
        </div>
    )

}
import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  Link,
  Flex,
  Switch,
  Collapse,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  SimpleGrid,
  Tab,
  TabList,
  Text,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
} from "@chakra-ui/react";

// import CheckTable from './components/CheckTable';
import { RowObj } from "../models/rowUserObj";
import { GetUsers } from "../models/userData";
import CheckTable from "./CheckTableUser";
import { useHistory } from "react-router-dom";
import Card from "components/card/Card";
import { GetAllUsers, User } from "../../../../services/AdminApi/users";
import { MdSearch, MdClose } from "react-icons/md";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useContextUser } from "../../../../hooks/contextApi";
import { transformDateToString } from "../../../../helper/formatDateToString";
import { useContextLocal } from "../variables/localContext";
import FilterUser from "./FilterUser";
import Pagination from "./Pagination";
export default function Users() {
  // Chakra Color Mode
  const { loading, getUserData, userData } = useContextUser();

  const {
    show,
    page,
    invoicing,
    limit,
    createdAt,
    name,
    situation,
    setParams,
  } = useContextLocal();

  const handleSituation = (users: User) => {
    if (users.total_stores == 0) return "Inativo";

    return "Ativo e inoperante";
  };
  useEffect(() => {
    (async () => {
      await getUserData({
        page,
        limit,
      });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      // console.log({ userData });
      const userTable: RowObj[] = userData.data.map((value: User) => {
        const createdAtString = transformDateToString(
          new Date(value.created_at)
        );
        const expiredSituationDateString = transformDateToString(
          new Date(value.expired_situation_date)
        );

        return {
          name: value.name,
          whatsapp: value.cellphone || "-",
          cidade: value.city,
          dataCadastro: createdAtString,
          status: value.complete_register ? "Sim" : "Não",
          qtdLojas: value.total_stores,
          situacao: value.situation_name,
          faturamento: value.invoicing || "-",
          dataSaida:
            value.situation_name === "Inativo" &&
            value.expired_situation_date !== null
              ? expiredSituationDateString
              : "-",
        };
      });

      console.log({
        total:  Math.floor(userData.total), 
        limit, 
        totalPages: Math.round(userData.total / limit)
      })
     
      setParams({ totalPages: Math.ceil(userData.total / limit) });

      setData(userTable);
    })();
  }, [userData]);

  const columnHelper = createColumnHelper<RowObj>();

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [data, setData] = React.useState<RowObj[]>([]);
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Nome
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("whatsapp", {
      id: "whatsapp",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Whatsapp
        </Text>
      ),
      cell: (info) => {
        if (info.getValue() === "-") {
          return (
            <Text color={textColor} fontSize="sm" fontWeight="700">
              -
            </Text>
          );
        } else {
          return (
            <Text color={textColor} fontSize="sm" fontWeight="700">
              <Link
                href={`https://api.whatsapp.com/send?phone=${info.getValue()}`}
                isExternal
              >
                {info.getValue()}
              </Link>
            </Text>
          );
        }
      },
    }),
    columnHelper.accessor("cidade", {
      id: "cidade",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Cidade/Estado
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("dataCadastro", {
      id: "dataCadastro",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Data do cadastro
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Status
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("qtdLojas", {
      id: "qtdLojas",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Qtd. lojas
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("situacao", {
      id: "situacao",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Situação
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("faturamento", {
      id: "faturamento",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Faturamento
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("dataSaida", {
      id: "dataSaida",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Data saída
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      // // columnFilters: []
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  if (!loading) {
    return <React.Fragment />;
  } else {
    return (
      <React.Fragment>
        <Button
        bg={"#ADD8E6"}
          size="md"
          leftIcon={!show ? <MdSearch />: <MdClose/>}
          alignSelf="flex-start"
          onClick={() => {
            setParams({ show: !show });
          }}
        >
          Filtros
        </Button>
          
        <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
          
          <Collapse in={show}>
          <FilterUser />
          </Collapse>
          
            <Box pt={{ base: "130px", md: "60px", xl: "40px" }}>
              <CheckTable tableData={data} table={table} tableName="Usuários" />
              <Pagination tableData={data} table={table} tableName="Lojas" />
            </Box>
          
        </SimpleGrid>
      </React.Fragment>
    );
  }
}

import {
  createContext,
  ReactNode,
  useState,
  useCallback,
  useContext,
} from "react";
import { RowObj } from "../views/admin/visaoLojista/models/rowUserObj";

import { getToken, GetToken } from "../services/AdminApi/auth";
import {
  getAllMembershipPlans,
  GetAllMembershipPlans,
  CreateMembershipPlans,
  createMembershipPlans,
  updateMembershipPlans,
  UpdateMembershipPlan
} from "../services/AdminApi/membershipPlan";

interface ProviderProps {
  children: ReactNode;
}

interface ContextProps {
  getMembershipPlan: () => Promise<GetAllMembershipPlans.Result>;
  membershipPlans: GetAllMembershipPlans.Result;
  loading: boolean;
  createMembershipPlan: (
    params: CreateMembershipPlans.Params
  ) => Promise<CreateMembershipPlans.Result>;
  updateMembershipPlan: (
    params: UpdateMembershipPlan.Params
  ) => Promise<UpdateMembershipPlan.Result>;
}

export const ContextUserData = createContext<ContextProps | null>(null);

export function MembershipContextProvider({ children }: ProviderProps) {
  const [membershipPlans, setMembershipPlans] =
    useState<GetAllMembershipPlans.Result>({ data: [], total: 0 });
  const [loading, setLoading] = useState(false);

  const getMembershipPlan = useCallback(async () => {
    try {
      setLoading(false);

      const response: GetAllMembershipPlans.Result =
        await getAllMembershipPlans();

      setMembershipPlans(response);

      return response;
    } catch (err: any) {
      setLoading(false);
      return {
        data: [],
        total: 0,
      };
      console.log(err.response.data.error);
    } finally {
      setLoading(true);
    }
  }, []);

  const updateMembershipPlan = useCallback(async (
    params: UpdateMembershipPlan.Params
  ): Promise<UpdateMembershipPlan.Result> => {
    try {
      const response: UpdateMembershipPlan.Result =
        await updateMembershipPlans({id: params.id, status: params.status, totalPrice: params.totalPrice});

        console.log({response})

      return response;
    } catch (err: any) {
      
     
      console.log(err.response.data.error);
    } finally {
      
    }
  }, []);



  const createMembershipPlan = useCallback(
    async (
      params: CreateMembershipPlans.Params
    ): Promise<CreateMembershipPlans.Result> => {
      try {
        setLoading(false);

        const response: CreateMembershipPlans.Result =
          await createMembershipPlans({
            name: params.name,
            total_price: params.total_price,
          });

        return response;
      } catch (err: any) {
        setLoading(false);
      } finally {
        setLoading(true);
      }
    },
    []
  );

  const contextValue: ContextProps = {
    getMembershipPlan,
    membershipPlans,
    loading,
    createMembershipPlan,
    updateMembershipPlan
  };

  return (
    <ContextUserData.Provider value={contextValue}>
      {children}
    </ContextUserData.Provider>
  );
}

export function useContextPlan() {
  const context = useContext(ContextUserData);

  return context;
}
